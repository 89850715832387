import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
// import Chart from 'react-apexcharts';

// project import

import DreamsSummaryCard from 'views/dreams/mysummary/cards/DreamsSummaryCard';
import MainCard from 'ui-component/cards/MainCard';
import { Button, CardActions, Divider, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { ReactComponent as DietIcon } from '../../../../assets/images/icons/dreams/diet.svg';
import { ReactComponent as RestIcon } from '../../../../assets/images/icons/dreams/rest.svg';
import { ReactComponent as ExerciseIcon } from '../../../../assets/images/icons/dreams/exercise.svg';
import { ReactComponent as MedicationIcon } from '../../../../assets/images/icons/dreams/medication.svg';
import { ReactComponent as AttitudeIcon } from '../../../../assets/images/icons/dreams/attitude.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'store';
import { activeItem } from 'store/slices/menu';

const ActivitySummary = ({ title }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClick = () => {
        navigate('/dreams');
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'dreams');
        if (currentIndex > -1) {
            dispatch(activeItem(['dreams']));
        }
    };

    return (
        <Grid item xs={12} lg={12} md={12}>
            <MainCard
                contentSX={{ p: '0px !important' }}
                title={
                    <Grid item xs={6}>
                        {/* My Activities-Calories */}
                        {/* My Activity Summary */}
                        {title}
                    </Grid>
                }
                sx={{ backgroundColor: theme.palette.cardColor.mainCardContentsBg }}
            >
                <Grid container item spacing={gridSpacing} xs={12} lg={12} md={12} sx={{ p: '8px !important' }}>
                    <Grid item xs={4} lg={4} md={4}>
                        <DreamsSummaryCard
                            iconPrimary={DietIcon}
                            pIcon="D"
                            domain="iet"
                            badgeContent="Good"
                            badgeColor={theme.palette.success.dark}
                            progressValue={80}
                            progressColor="success"
                        />
                    </Grid>
                    <Grid item xs={4} lg={4} md={4}>
                        <DreamsSummaryCard
                            iconPrimary={RestIcon}
                            pIcon="R"
                            domain="est"
                            badgeContent="Good"
                            badgeColor={theme.palette.success.dark}
                            progressValue={80}
                            progressColor="success"
                        />
                    </Grid>
                    <Grid item xs={4} lg={4} md={4}>
                        <DreamsSummaryCard
                            iconPrimary={ExerciseIcon}
                            pIcon="E"
                            domain="xercise"
                            badgeContent="Poor"
                            badgeColor={theme.palette.error.dark}
                            progressValue={40}
                            progressColor="error"
                        />
                    </Grid>
                    <Grid item xs={4} lg={4} md={4}>
                        <DreamsSummaryCard
                            iconPrimary={AttitudeIcon}
                            pIcon="A"
                            domain="ttitude"
                            badgeContent="Not Planned"
                            badgeColor={theme.palette.grey[700]}
                            progressValue={0}
                            progressColor="inherit"
                        />
                    </Grid>
                    <Grid item xs={4} lg={4} md={4}>
                        <DreamsSummaryCard
                            iconPrimary={MedicationIcon}
                            pIcon="M"
                            domain="edication"
                            badgeContent="Good"
                            badgeColor={theme.palette.success.dark}
                            progressValue={80}
                            progressColor="success"
                        />
                    </Grid>
                    <Grid item xs={4} lg={4} md={4}>
                        <DreamsSummaryCard
                            iconPrimary={MedicationIcon}
                            pIcon="S"
                            domain="pecialist"
                            badgeContent="Pending"
                            badgeColor={theme.palette.success.dark}
                            progressValue={0}
                            progressColor="success"
                        />
                    </Grid>
                </Grid>
                <Divider />
                <Grid item xs={12} lg={12} md={12}>
                    {/* <Divider sx={{ pt: 1 }} /> */}
                    <CardActions sx={{ p: 1, justifyContent: 'flex-end', backgroundColor: theme.palette.primary.light }}>
                        <Button variant="text" size="small" onClick={handleClick}>
                            View DREAMS details
                        </Button>
                    </CardActions>
                </Grid>
            </MainCard>
        </Grid>
    );
};

export default ActivitySummary;
