import MainCard from 'ui-component/cards/MainCard';
import ProxyUserList from '../ProxyUserList';
import { Card, useTheme, Grid, CardActions, CardContent, Divider, Typography, Button } from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';
import MuiTypography from '@mui/material/Typography';
import TodoImg from './To-do list.svg';
import { gridSpacing } from 'store/constant';

const Todo = () => {
    const theme = useTheme();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#e9e8e8'
    };
    return (
        <MainCard title="Things to Note" content={false}>
            <Grid container sx={{ backgroundColor: theme.palette.cardColor.dashboardCardContentBg }}>
                <Grid item xs={8}>
                    <Grid container>
                        <Grid item xs={12} sx={{ pt: 6, pl: 2 }}>
                            <Typography variant="dashboardCardContent">No data available.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container>
                        <Grid item sx={{ pt: 3, pb: 2 }}>
                            <img src={TodoImg} alt="Todo" width="100" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default Todo;
