import { Typography, CardActions, Button, Grid, Menu, MenuItem, Stack, CardContent, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import ItemsCarousel from 'react-items-carousel';
import { ReactComponent as calorieIcon } from '../../../assets/images/dashboard/calorie-icon.svg';
import { ReactComponent as sleepIcon } from '../../../assets/images/dashboard/sleep-icon.svg';
import { ReactComponent as stepsIcon } from '../../../assets/images/dashboard/steps-icon.svg';
import SideIconCard from './SideIconCard';
import MainCard from 'ui-component/cards/MainCard';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useNavigate } from 'react-router-dom';
import { activeItem } from 'store/slices/menu';
import { useDispatch } from 'store';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const MySummary = () => {
    const [active, setaAtive] = useState(0);
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('xl'));
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [numberOfCards, setNumberOfCards] = useState(3);
    useEffect(() => {
        if (matchDownSm) {
            setNumberOfCards(1);
        } else if (matchDownMd) {
            setNumberOfCards(2);
        } else {
            setNumberOfCards(3);
        }
    }, [matchDownMd, matchDownSm]);
    const dispatch = useDispatch();
    const handleClick = () => {
        navigate('/dreams');
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'dreams');
        if (currentIndex > -1) {
            dispatch(activeItem(['dreams']));
        }
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [mode, setMode] = useState(null);
    const dropData = {
        title: 'Today',
        options: [
            {
                value: 1,
                label: 'Weekly'
            },
            {
                value: 2,
                label: 'Monthly'
            }
        ]
    };
    let dropHtml;
    if (dropData) {
        const handleClick = (event) => {
            setAnchorEl(event?.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        dropHtml = (
            <>
                <Button
                    variant="text"
                    disableElevation
                    size="medium"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    endIcon={<ExpandCircleDownOutlinedIcon />}
                >
                    {dropData.title}
                </Button>
                <Menu
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {dropData?.options.map((option, index) => (
                        <MenuItem key={index} onClick={handleClose}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    }

    const summaries = [
        {
            iconPrimary: calorieIcon,
            iconPrimaryName: 'Calorie',
            units: '(In cals)',
            primarySub: '2500',
            secondarySub: '2200',
            superscript: true,
            iconBgColor: theme.palette.dreams.diet.primary
            // color={theme.palette.secondary.main}}]
        },
        {
            iconPrimary: sleepIcon,
            iconPrimaryName: 'Sleep',
            units: '(In hrs)',
            primarySub: '8',
            secondarySub: '8',
            superscript: true,
            iconBgColor: theme.palette.dreams.rest.primary
            // color={theme.palette.secondary.main}}]
        },
        {
            iconPrimary: stepsIcon,
            iconPrimaryName: 'Weight',
            units: '(In lbs)',
            primarySub: '180',
            secondarySub: '184',
            iconBgColor: theme.palette.dreams.exercise.primary
            // color={theme.palette.secondary.main}}]
        }
    ];
    return (
        <MainCard
            contentSX={{ pb: '0px !important', pt: '8px !important' }}
            // headerStyle={{ pb: '8px !important' }}
            content={false}
            title={
                <>
                    <Grid container alignItems="center">
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="h3" color="secondary">
                                    My Goals Summary
                                </Typography>
                                {/* {dropHtml} */}
                            </Stack>
                        </Grid>
                    </Grid>
                </>
            }
            sx={{ backgroundColor: theme.palette.primary.light }}
        >
            <Grid item xs={12} lg={12} md={12}>
                <CardContent sx={{ p: 3, pb: '0px !important', backgroundColor: theme.palette.primary.light }}>
                    <Grid container item xs={12} lg={12} md={12} spacing={1}>
                        {/* <Grid item xs={12} lg={12} md={12} align="right">
                            <Stack direction="row" justifyContent="flex-end">
                                <Button variant="text" startIcon={<NavigateBeforeIcon />}>
                                    Previous Week
                                </Button>
                                <Button variant="text" endIcon={<NavigateNextIcon />}>
                                    Next Week
                                </Button>
                            </Stack>
                        </Grid> */}
                        <Grid item xs={12} lg={12} md={12}>
                            <ItemsCarousel
                                infiniteLoop="false"
                                gutter={8}
                                activePosition="center"
                                chevronWidth={22}
                                disableSwipe={false}
                                alwaysShowChevrons={false}
                                numberOfCards={numberOfCards}
                                slidesToScroll={1}
                                outsideChevron="true"
                                // showSlither="false"
                                firstAndLastGutter={false}
                                activeItemIndex={active}
                                requestToChangeActive={(value) => setaAtive(value)}
                                leftChevron={<ArrowLeftIcon sx={{ fontSize: '60px' }} color="primary" />}
                                rightChevron={<ArrowRightIcon sx={{ fontSize: '60px' }} color="primary" />}
                            >
                                {summaries.map((item, index) => (
                                    // <Grid item xs={12} lg={3} sm={6}>
                                    <SideIconCard
                                        iconPrimary={item.iconPrimary}
                                        iconPrimaryName={item.iconPrimaryName}
                                        primary="Goal"
                                        primarySub={item.primarySub}
                                        secondary="Achieved"
                                        secondarySub={item.secondarySub}
                                        color={theme.palette.secondary.main}
                                        superscript={item.superscript}
                                        units={item.units}
                                        // bgcolor={theme.palette.secondary.light}
                                        bgcolor={theme.palette.cardColor.mainCardContentsBg}
                                        iconBgColor={item.iconBgColor}
                                    />
                                    // </Grid>
                                ))}
                            </ItemsCarousel>
                        </Grid>
                    </Grid>
                </CardContent>
            </Grid>

            <CardActions sx={{ p: 1, justifyContent: 'flex-end' }}>
                <Grid container item xs={12} lg={12} md={12} alignItems="center">
                    <Grid item xs={6} lg={6} md={6} align="left">
                        <Typography>* Average readings from the goal start date</Typography>
                    </Grid>
                    <Grid item xs={6} lg={6} md={6} align="right">
                        <Button variant="text" size="small" onClick={handleClick} align="right">
                            <b>View DREAMS Summary</b>
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </MainCard>
    );
};

export default MySummary;
