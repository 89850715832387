// material-ui
import { Table, TableContainer, TableHead, TableRow, Card, useTheme } from '@mui/material';
// project imports
import SubCard from 'ui-component/cards/SubCard';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

// ==============================|| TABLE - DENSE ||============================== //

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        width: '15%'
    }
    /*  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    }, */
}));

export default function HealthSummaryInitialTable() {
    const theme = useTheme();
    /*   const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#e9e8e8',
        minWidth: 600
    }; */
    return (
        <SubCard>
            {/*  <Grid item sx={{ pl: 3 }}>
                <MuiTypography variant="h4" color={theme.palette.text.dark} gutterBottom>
                    Vital Signs
                </MuiTypography>
            </Grid> */}
            <TableContainer sx={{ border: '1px solid', borderColor: 'Black' }}>
                <Table size="small" aria-label="a dense table">
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Patient
                        </StyledTableCell>
                        <TableCell align="left">Robert Hailey</TableCell>
                        <StyledTableCell align="left" variant="head">
                            Preferred Language
                        </StyledTableCell>
                        <TableCell align="left">English</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Date Of Birth
                        </StyledTableCell>
                        <TableCell align="left">April 13, 1963</TableCell>
                        <StyledTableCell align="left" variant="head">
                            Sex
                        </StyledTableCell>
                        <TableCell align="left">Male</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Race
                        </StyledTableCell>
                        <TableCell align="left">American Indian or Alaska Native</TableCell>
                        <StyledTableCell align="left" variant="head">
                            Ethnicity
                        </StyledTableCell>
                        <TableCell align="left">Hispanic or Latino</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Contact Info
                        </StyledTableCell>
                        <TableCell align="left">
                            100 John Doe Street
                            <br /> San Antonio, TX 78258
                            <br /> Tel: 23456789432
                            <br /> Mail: roberthailey@mail.com
                        </TableCell>
                        <StyledTableCell align="left" variant="head">
                            Patient IDs
                        </StyledTableCell>
                        <TableCell align="left">2.16.840.1.113883.19.5</TableCell>
                    </TableRow>
                </Table>
                <Table size="small" aria-label="a dense table">
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Document Id
                        </StyledTableCell>
                        <TableCell align="left">Consolidated Health Record f61b5475</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Document Created
                        </StyledTableCell>
                        <TableCell align="left">May 3, 2022, 03.33 AM, PST</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Care Provision
                        </StyledTableCell>
                        <TableCell align="left">From April 13, 1963 to May 20, 2022</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Primary Performer
                        </StyledTableCell>
                        <TableCell align="left">Dr. Roger Miles</TableCell>
                    </TableRow>{' '}
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Provider
                        </StyledTableCell>
                        <TableCell align="left">Sutter Health</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Contact info
                        </StyledTableCell>
                        <TableCell align="left">
                            20770 Highway 281 North
                            <br />
                            Suite 108, PMB 206
                            <br />
                            San Antonio, Tx 78258
                            <br />
                            Tel(workPlace): +1(111)-8765-4323
                        </TableCell>
                    </TableRow>
                    {/*   <TableRow>
                        <TableCell align="left" variant="head">
                            Guardian
                        </TableCell>
                        <TableCell align="left">Robert Hailey</TableCell>
                    </TableRow> */}
                </Table>
            </TableContainer>
        </SubCard>
    );
}
