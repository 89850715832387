// material-ui
import { Table, TableBody, Grid, TableContainer, TableHead, TableRow, Card, useTheme } from '@mui/material';
import MuiTypography from '@mui/material/Typography';
// project imports
import SubCard from 'ui-component/cards/SubCard';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.common.white
    }
    /*  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    }, */
}));

// table data
function createData(lcode, ltest, nrange, result, date, ssource, flag) {
    return { lcode, ltest, nrange, result, date, ssource, flag };
}

const rows = [createData('4548-4', 'Haemoglobin', '%', '8.1 %', '10/02/2020', '-', '-')];

// ==============================|| TABLE - DENSE ||============================== //

export default function LabResultsTable() {
    const theme = useTheme();
    /*   const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#e9e8e8',
        minWidth: 600
    }; */
    return (
        <SubCard>
            <Grid item>
                <MuiTypography variant="h4" color={theme.palette.text.dark} gutterBottom>
                    Lab Results
                </MuiTypography>
            </Grid>
            <TableContainer sx={{ border: '1px solid', borderColor: 'Black' }}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">LOINC Code</StyledTableCell>
                            <StyledTableCell align="left">Lab Test</StyledTableCell>
                            <StyledTableCell align="left">Normal Range</StyledTableCell>
                            <StyledTableCell align="left">Result</StyledTableCell>
                            <StyledTableCell align="left">Date</StyledTableCell>
                            <StyledTableCell align="left">Specimen Source</StyledTableCell>
                            <StyledTableCell align="left">Abnormal Flag</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow hover key={row.name}>
                                <TableCell align="left">{row.lcode}</TableCell>
                                <TableCell align="left">{row.ltest}</TableCell>
                                <TableCell align="left">{row.nrange}</TableCell>
                                <TableCell align="left">{row.result}</TableCell>
                                <TableCell align="left">{row.date}</TableCell>
                                <TableCell align="left">{row.ssource}</TableCell>
                                <TableCell align="left">{row.flag}</TableCell>
                                {/* <TableCell sx={{ pr: 3 }} align="right">
                                    {row.protein}
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </SubCard>
    );
}
