import CallIcon from '@mui/icons-material/Call';
// assets
import EmailIcon from '@mui/icons-material/Email';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { gridSpacing } from 'store/constant';

const avatarImage = require.context('assets/images/careteam', true);

// ==============================|| USER PROFILE CARD ||============================== //

const CareTeamProfileCard = ({ avatar, name, role, status, provider, email, mobile }) => {
    const theme = useTheme();
    const avatarProfile = avatar && avatarImage(`./${avatar}`).default;
    // const imageProfile = profile && avatarImage(`./${profile}`).default;

    return (
        <Card
            sx={{
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                border: theme.palette.mode === 'dark' ? 'none' : '1px solid',
                borderColor: theme.palette.common.grey,
                textAlign: 'center'
            }}
        >
            {/* <CardMedia component="img" image={imageProfile} title="Slider5 image" sx={{ height: '125px' }} /> */}
            <CardContent sx={{ p: 2, pb: '16px !important' }}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                {/* <Avatar alt={name} src={avatarProfile} sx={{ width: 72, height: 72, m: '-50px auto 0' }} /> */}
                                <Avatar alt="User 1" src={avatarProfile} sx={{ width: 72, height: 72, m: '-50px auto 0', mt: 2 }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} alignItems="center">
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h4">{name}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">{role}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <EmailIcon size="small" />
                        &nbsp;&nbsp;
                        <CallIcon size="small" />
                        &nbsp;&nbsp;
                        <VideocamIcon size="small" />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

CareTeamProfileCard.propTypes = {
    avatar: PropTypes.string,
    name: PropTypes.string,
    // profile: PropTypes.string,
    role: PropTypes.string,
    status: PropTypes.string
};

export default CareTeamProfileCard;
