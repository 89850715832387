import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import { ReactComponent as calorieIcon } from '../../../../assets/images/dashboard/calorie-icon.svg';
import { ReactComponent as sleepIcon } from '../../../../assets/images/dashboard/sleep-icon.svg';
import { ReactComponent as stepsIcon } from '../../../../assets/images/dashboard/steps-icon.svg';
import SideIconCard from './SideIconCard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const SummaryTab = () => {
    const [active, setaAtive] = useState(0);
    const theme = useTheme();
    const summaries = [
        {
            iconPrimary: calorieIcon,
            iconPrimaryName: 'Calorie',
            primarySub: '1200',
            secondarySub: '1300'
            // color={theme.palette.secondary.main}}]
        },
        {
            iconPrimary: sleepIcon,
            iconPrimaryName: 'Sleep',
            primarySub: '8 Hrs',
            secondarySub: '8 Hrs'
            // color={theme.palette.secondary.main}}]
        },
        {
            iconPrimary: stepsIcon,
            iconPrimaryName: 'Steps',
            primarySub: '10000',
            secondarySub: '8000'
            // color={theme.palette.secondary.main}}]
        }
    ];
    return (
        <>
            <Typography variant="h4" sx={{ p: 1 }}>
                My Goals
            </Typography>
            <ItemsCarousel
                infiniteLoop="false"
                gutter={8}
                activePosition="center"
                chevronWidth={22}
                disableSwipe={false}
                alwaysShowChevrons={false}
                numberOfCards={3}
                slidesToScroll={1}
                outsideChevron="true"
                // showSlither="false"
                firstAndLastGutter={false}
                activeItemIndex={active}
                requestToChangeActive={(value) => setaAtive(value)}
                rightChevron={<ArrowForwardIosIcon sx={{ fontSize: '30px' }} />}
                leftChevron={<ArrowBackIosNewIcon sx={{ fontSize: '30px' }} />}
            >
                {summaries.map((item, index) => (
                    // <Grid item xs={12} lg={3} sm={6}>
                    <SideIconCard
                        iconPrimary={item.iconPrimary}
                        iconPrimaryName={item.iconPrimaryName}
                        primary="Goal"
                        primarySub={item.primarySub}
                        secondary="Achieved"
                        secondarySub={item.secondarySub}
                        color={theme.palette.secondary.main}
                        bgcolor={theme.palette.secondary.light}
                    />
                    // </Grid>
                ))}
            </ItemsCarousel>
        </>
    );
};

export default SummaryTab;
