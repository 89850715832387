/* eslint-disable consistent-return */
import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button, Card, CardContent, Chip, Divider, Drawer, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dispatch } from 'store';
import { openSideDrawer, setDrawerData } from 'store/slices/dashboard';
import MainCard from '../../../ui-component/cards/MainCard';
import RateSleepQuality from './RateSleepQuality';
import { ReactComponent as SleepIcon } from '../../../assets/images/dashboard/sleep-icon.svg';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const Todo = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [todo, setTodo] = useState({});
    const dashboard = useSelector((state) => state.dashboard);
    const { sideDrawer, drawerData } = dashboard;
    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));

    const handleDrawerOpen = (todo) => {
        setOpen((prevState) => !prevState);
        dispatch(openSideDrawer(true));
        dispatch(setDrawerData(todo));
        // setTodo(todo);
    };
    const handleDrawerClose = () => {
        dispatch(setDrawerData({}));
        dispatch(openSideDrawer(false));
        setOpen(false);
    };
    const todoList = [
        { task: 'Alex needs to get oats today', type: 'Reminder' },
        { task: 'You are walking less this week compared to last week ', type: 'Reminder' },
        { task: 'Your weight is above target and trending up', type: 'Alert' },
        { task: 'Dr.Miles on 04/16/2022, 10:00AM PST ', type: 'Appointment' },
        { task: 'Rate your sleep quality', type: 'Reminder', content: true, title: 'Sleep Quality' }
    ];

    useEffect(() => {
        setTodo(drawerData);
    }, [matchDownSM, open]);

    return (
        <>
            {sideDrawer && (
                <Drawer
                    sx={{
                        ml: open ? 3 : 0,
                        flexShrink: 0,
                        zIndex: 1200,
                        overflowX: 'hidden',
                        width: { xs: 320, md: 450 },
                        '& .MuiDrawer-paper': {
                            height: '100vh',
                            width: { xs: '100%', md: 450 },
                            position: 'fixed',
                            border: 'none',
                            borderRadius: '0px'
                        }
                    }}
                    variant="temporary"
                    anchor="right"
                    open={open}
                    ModalProps={{ keepMounted: true }}
                    onClose={handleDrawerOpen}
                >
                    <Box sx={{ p: 3 }}>
                        <Grid container alignItems="center" spacing={0.5} justifyContent="space-between">
                            <Grid item sx={{ width: 'calc(100% - 50px)' }}>
                                <Stack direction="row" spacing={0.5} alignItems="center">
                                    <Typography
                                        variant="h4"
                                        color="secondary"
                                        sx={{
                                            display: 'inline-block',
                                            width: 'calc(100% - 34px)',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            verticalAlign: 'middle'
                                        }}
                                    >
                                        {todo.title ? todo.title : todo.task}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <IconButton sx={{ p: 0.5, minWidth: 32 }} onClick={() => handleDrawerClose()}>
                                    <CloseIcon />
                                </IconButton>
                                {/* <Button variant="text" onClick={() => setOpen(true)}>
                                    <CloseIcon />
                                </Button> */}
                            </Grid>
                            {todo.content && (
                                <Grid item xs={12} lg={12} md={12}>
                                    <RateSleepQuality iconPrimary={SleepIcon} handleDrawerClose={handleDrawerClose} />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Drawer>
            )}
            <MainCard
                sx={{ backgroundColor: theme.palette.cardColor.dashboardCardHeaderBg }}
                title={
                    <>
                        <Typography variant="h3" color="secondary">
                            Things to Note
                        </Typography>
                    </>
                }
                content={false}
            >
                <Grid xs={12}>
                    {todoList.map((todo, index) => (
                        <Grid container spacing={0}>
                            <Grid item xs={12} lg={12} md={12}>
                                <Card>
                                    <CardContent
                                        sx={{
                                            pt: 2,
                                            pb: '0px !important',
                                            pl: '16px !important',
                                            pr: 0,
                                            backgroundColor: theme.palette.primary.light
                                        }}
                                    >
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} lg={12} md={12}>
                                                {todo.type === 'Reminder' && (
                                                    <Chip
                                                        label={todo.type}
                                                        size="small"
                                                        sx={{
                                                            color: theme.palette.primary.light,
                                                            backgroundColor: theme.palette.secondary.main
                                                        }}
                                                    />
                                                )}
                                                {todo.type === 'Alert' && <Chip label={todo.type} size="small" color="error" />}
                                                {todo.type === 'Appointment' && (
                                                    <Chip
                                                        label={todo.type}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: theme.palette.secondary.secondary2,
                                                            color: theme.palette.primary.light
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={10} lg={10} md={10} sx={{ pt: 1, pb: 1 }}>
                                                <Typography align="left" variant="dashboardCardContent" color="secondary">
                                                    {todo.task}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} lg={2} md={2} align="center">
                                                <IconButton size="small" onClick={() => handleDrawerOpen(todo)}>
                                                    <ArrowRightIcon color="primary" fontSize="large" />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Divider />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </MainCard>
        </>
    );
};

export default Todo;
