import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import PatientVerification from 'views/patientonboarding/PatientVerification';
import PatientVerificationCode from 'views/patientonboarding/PatientVerificationCode';
import ProxyVerificationCode from 'views/proxyonboarding/ProxyVerificationCode';
import PatientSignUp from 'views/patientonboarding/PatientSignUp';
import SecurityQuestions from 'views/patientonboarding/SecurityQuestions';
import UserAgreement from 'views/patientonboarding/UserAgreement';
import PrivacyPolicy from 'views/patientonboarding/PrivacyPolicy';
import Dashboard from 'views/dashboard';
import ProxyUserAgreement from 'views/proxyonboarding/ProxyUserAgreement';
import ProxyPrivacyPolicy from 'views/proxyonboarding/ProxyPrivacyPolicy';
import ProxySignUp from 'views/proxyonboarding/ProxySignUp';
import ProxyCredentialsVerification from 'views/proxyonboarding/ProxyCredentialsVerification';
import ProxySecurityQuestions from 'views/proxyonboarding/ProxySecurityQuestions';
import PatientForgotUsername from 'views/patientforgotusername';
import ForgotMailSent from 'views/patientforgotusername/ForgotMailSent';
import ForgotPasswordCard from 'views/patientforgotpassword/ForgotPasswordCard';
import PatientPwdVerification from 'views/patientforgotpassword/PatientVerification';
import PatientPwdVerificationCode from 'views/patientforgotpassword/PatientVerificationCode';
import ForgotPassword from 'views/patientforgotpassword';
import ForgotPasswordSecurityQuestion from 'views/patientforgotpassword/ForgotPasswordSecurityQuestion';
import ResetPassword from 'views/patientforgotpassword/ResetPassword';
import PasswordChanged from 'views/patientforgotpassword/PasswordChanged';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const PatientLogin = Loadable(lazy(() => import('views/patientonboarding')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const PatientDashboard = Loadable(lazy(() => import('views/patientonboarding/patientinitialdashboard')));
const ProxyOnBoard = Loadable(lazy(() => import('views/proxyonboarding')));

// hub page
const HubPage = Loadable(lazy(() => import('views/hub')));
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/home',
            element: <HubPage />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/patientonboarding',
            element: <PatientLogin />
        },
        {
            path: '/patientonboarding/PatientVerification',
            element: <PatientVerification />
        },

        {
            path: '/patientonboarding/PatientVerificationCode',
            element: <PatientVerificationCode />
        },

        {
            path: '/patientonboarding/PatientSignUp',
            element: <PatientSignUp />
        },
        {
            path: '/patientonboarding/SecurityQuestions',
            element: <SecurityQuestions />
        },
        {
            path: '/patientonboarding/UserAgreement',
            element: <UserAgreement />
        },
        {
            path: '/patientonboarding/PrivacyPolicy',
            element: <PrivacyPolicy />
        },
        {
            path: '/dashboard',
            element: <PatientDashboard />
        },
        {
            path: '/proxyonboarding',
            element: <ProxyOnBoard />
        },
        {
            path: '/proxyonboarding/ProxyVerificationCode',
            element: <ProxyVerificationCode />
        },
        {
            path: '/proxyonboarding/ProxyCredentialsVerification',
            element: <ProxyCredentialsVerification />
        },
        {
            path: '/proxyonboarding/ProxySignUp',
            element: <ProxySignUp />
        },
        {
            path: '/proxyonboarding/ProxySecurityQuestions',
            element: <ProxySecurityQuestions />
        },
        {
            path: '/proxyonboarding/ProxyUserAgreement',
            element: <ProxyUserAgreement />
        },
        {
            path: '/proxyonboarding/ProxyPrivacyPolicy',
            element: <ProxyPrivacyPolicy />
        },
        {
            path: '/patientforgotusername',
            element: <PatientForgotUsername />
        },
        {
            path: '/patientforgotusername/ForgotMailSent',
            element: <ForgotMailSent />
        },
        {
            path: '/patientforgotpassword',
            element: <ForgotPassword />
        },
        {
            path: '/patientforgotpassword/PatientVerification',
            element: <PatientPwdVerification />
        },
        {
            path: '/patientforgotpassword/PatientVerificationCode',
            element: <PatientPwdVerificationCode />
        },
        {
            path: '/patientforgotpassword/SecurityQuestion',
            element: <ForgotPasswordSecurityQuestion />
        },
        {
            path: '/patientforgotpassword/ResetPassword',
            element: <ResetPassword />
        },
        {
            path: '/patientforgotpassword/PasswordChanged',
            element: <PasswordChanged />
        }
    ]
};

export default LoginRoutes;
