// material-ui
import { useTheme } from '@emotion/react';
import { Grid, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import AccountSettingsTabs from './accountsettingstab';
import MainCard from 'ui-component/cards/MainCard';

// third-party
import { FormattedMessage } from 'react-intl';

const AccountSettings = () => {
    const theme = useTheme();

    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Grid item xs={12} sx={{ mb: 4, mt: 2 }}>
                        <Grid item>
                            <Typography variant="h3">
                                <FormattedMessage id="Account_Settings" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <MainCard>
                        <div>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <AccountSettingsTabs />
                                </Grid>
                            </Grid>
                        </div>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default AccountSettings;
