import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, Grid, Typography, useMediaQuery, LinearProgress, Badge, styled, Stack, Chip } from '@mui/material';

// =============================|| SIDE ICON CARD ||============================= //

const DreamsSummaryCard = ({ iconPrimary, iconBackground, domain, badgeContent, badgeColor, progressValue, progressColor, pIcon }) => {
    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));

    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary !== undefined ? <IconPrimary /> : null;
    const CustomBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            background: badgeColor,
            top: -2,
            color: theme.palette.primary.light,
            // width: '80px',
            hieght: '100px',
            border: `1px solid ${badgeColor}`,
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '5px',
            paddingRight: '5px'
        }
    }));
    const dreamsindicatorstyle = {
        fontSize: '16px',
        color: '#ffffff',
        width: '25px',
        height: '25px',
        textAlign: 'center',
        display: 'inline-block',
        boxShadow: 'rgb(0 0 0 / 18%) 0px 1rem 4rem',
        lineHeight: '25px',
        borderRadius: '50%',
        fontWeight: 'bold',
        backgroundColor: '#545353',
        marginRight: '1px'
    };
    return (
        <Card
            sx={{
                position: 'relative',
                border: `1px solid ${theme.palette.common.grey}`,
                // background: theme.palette.cardColor.mainCardContentsBg
                background: theme.palette.primary.light
            }}
        >
            <Grid container justifyContent="space-between" alignItems="center">
                {/* <Grid item xs={2} sx={{ py: 3.5, pl: 2 }}>
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: 'center !important',
                            backgroundColor: iconBackground,
                            // color: badgeColor,
                            '& > svg': {
                                width: 32,
                                height: 32
                            }
                        }}
                        align="center"
                    >
                        {iconPrimary !== undefined && primaryIcon}
                        {iconPrimary === undefined && (
                            <span className="dreamsIndicator" style={dreamsindicatorstyle}>
                                {pIcon}
                            </span>
                        )}
                    </Typography>
                </Grid> */}
                <Grid item xs={12} sx={{ pr: 2.5, py: 1, pl: 3 }}>
                    <Grid container justifyContent="space-between" spacing={1} alignItems={matchDownXs ? 'center' : 'flex-start'}>
                        <Grid item sm={7}>
                            <Stack direction="row">
                                <span className="dreamsIndicator" style={dreamsindicatorstyle}>
                                    {pIcon}
                                </span>
                                <Typography variant="h3">{domain}</Typography>
                            </Stack>
                        </Grid>
                        {progressValue !== 0 && (
                            <Grid item sm={3} align="right" sx={{ pr: 2.5 }}>
                                <Typography variant="h3">
                                    <CustomBadge badgeContent={badgeContent} />
                                </Typography>
                            </Grid>
                        )}
                        {/* {secondary} <span style={{ color }}>{secondarySub}</span>{' '} */}
                        {progressValue !== 0 && (
                            <Grid item sm={12} sx={{ mt: 1 }}>
                                <Typography variant="body2">
                                    <LinearProgress variant="determinate" value={progressValue} color={progressColor} />
                                </Typography>
                            </Grid>
                        )}
                        {progressValue === 0 && (
                            <>
                                <Grid item sm={5} align="right">
                                    <Typography variant="h3">
                                        <Chip size="small" label={badgeContent} />
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} sx={{ mt: 1 }} />
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

DreamsSummaryCard.propTypes = {
    iconPrimary: PropTypes.object,
    iconBackground: PropTypes.string,
    badgeContent: PropTypes.string,
    badgeColor: PropTypes.string,
    domain: PropTypes.string
};

export default DreamsSummaryCard;
