import {
    Box,
    Grid,
    Button,
    Typography,
    IconButton,
    CardContent,
    CardActions,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Divider,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MainCard from 'ui-component/cards/MainCard';
import Modal from '@mui/material/Modal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage } from 'react-intl';

const PrivacyPolicyCard = () => {
    const navigate = useNavigate();
    const rootRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    return (
        <Grid container direction="column" xs={12} lg={12} spacing={2}>
            <Grid item alignItems="center" spacing={2}>
                <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                    <Box sx={{ p: 3, border: '0.5px solid #E3E3E3' }}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="subtitle1" align="justify">
                                    This notice describes how personal and medical information about you may be used and disclosed and how
                                    you may access information concerning this process. Please review it carefully.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" align="justify">
                                    Vital eCare believes your personal and medical information should remain confidential. In addition, the
                                    law requires us to establish formal office policies that are designated to safeguard your protected
                                    health information (PHI). This notice constitutes our promise to you that we acknowledge our
                                    responsibility and legal obligation to protect your personal and medical information and describes your
                                    rights concerning our use of that information.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography align="justify" fontSize={16}>
                                    We will use and disclose your health information for purposes of treatment and/or health care
                                    operations.
                                </Typography>
                                <Typography variant="body1">
                                    <ol align="justify">
                                        <li>
                                            <b>Treatment</b> means the provision, coordination or management of health care and any related
                                            services rendered to you, by one or more health care providers, including the coordination or
                                            management of health care by a health care provider with a third party. This includes
                                            consultation between one of our health care providers and a specialist on your behalf, or your
                                            referral to a health care provider who specializes in specific treatment that will be of benefit
                                            to you. Any information received as a result of that consultation or referral is part of your
                                            medical information and will be protected.
                                        </li>
                                        <li>
                                            <b>Health Care Operations</b> include activities of the practice such as our own internal
                                            auditing procedures, business management and planning or activities related to legal or
                                            accounting services. Organized hearth care plans, in which we participate, also may have
                                            programs to assure quality of care and improvement for the services we render to our patients or
                                            to review the qualifications and competence of our healthcare professionals. This may require a
                                            random review of patient records.
                                        </li>
                                    </ol>
                                </Typography>
                            </Grid>
                            <Divider />
                            <Grid item>
                                <Typography align="justify" fontSize={16}>
                                    We are permitted or required to disclose limited health information about you, without your
                                    authorization, in the following circumstances:
                                </Typography>
                                <Typography variant="body1">
                                    <ul align="justify">
                                        <li>
                                            <strong>As required by law</strong> &#45; any information limited to the relevant requirements
                                            of the law
                                        </li>
                                        <li>
                                            <strong>For public health activities</strong> (disease control, vital statistics, public health)
                                        </li>
                                        <li>
                                            <strong>Reporting victims</strong> of abuse, neglect or domestic violence
                                        </li>
                                        <li>
                                            <strong>Health oversight activities</strong> (audits, civil, criminal or administrative
                                            investigations)
                                        </li>
                                        <li>
                                            <strong>Judicial and administrative proceedings,</strong> in response to court order,
                                        </li>
                                        <li>
                                            <strong>To coroners, medical examiners and funeral directors</strong> (identifying disease
                                            process or cause of death)
                                        </li>
                                        <li>
                                            <strong>For organ or tissue donation,</strong> consistent with applicable laws
                                        </li>
                                        <li>
                                            <strong>To avert serious threats to health or safety</strong>
                                        </li>
                                        <li>
                                            <strong>Specialized government functions</strong> (regarding military personnel, veterans,
                                            national security purposes or inmates)
                                        </li>
                                        <li>
                                            <strong>Worker&#39;s compensation</strong> to the extent necessary to comply with applicable
                                            laws.
                                        </li>
                                    </ul>
                                </Typography>
                            </Grid>
                            <Divider />
                            <Grid item>
                                <Typography align="justify" variant="body1">
                                    <strong>
                                        Any uses or disclosures other than those noted previously require us to obtain your written
                                        authorization. Specifically, the following uses and disclosures will be made only with authorization
                                        from you: (i) most uses and disclosures of psychotherapy notes; (ii) uses and disclosures of PHI for
                                        marketing purposes, including subsidized treatment communications; (iii) disclosures that constitute
                                        a sale of PHI under applicable law; and (iv) other uses and disclosures not described in this
                                        notice. In addition, you will be notified of any breach of your unsecured PHI. You may revoke your
                                        written authorization at any time, however, any such revocation must be in writing.{' '}
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography align="justify" fontSize={16}>
                                    You have the following rights with respect to your health information:
                                </Typography>
                                <Typography variant="body1">
                                    <ol align="justify">
                                        <li>
                                            The right to request restrictions on certain uses of your health information;{' '}
                                            <strong>however we are not required to agree to your request.</strong>
                                        </li>
                                        <li>
                                            The right to request, <strong>in writing,</strong> the manner or method by which we may contact
                                            you to furnish confidential communications about your health information (Refer to our Contact
                                            Us link on the web site). You are obligated to notify us, <strong>in writing,</strong> of any
                                            changes to your request.
                                        </li>
                                        <li>
                                            The right to review your health information{' '}
                                            <strong>
                                                (you are entitled to receive a copy of your health information, except for psychotherapy
                                                notes and information compiled in anticipation of or for use in, a civil, criminal or
                                                administrative action or proceeding).
                                            </strong>
                                        </li>
                                        <li>
                                            {' '}
                                            In limited circumstances, the right to ask us, <strong>in writing,</strong> to amend your health
                                            information, <strong>however we reserve the right to deny your request.</strong> If your request
                                            for amendment is denied, we will provide you with information about the basis of our denial.
                                        </li>
                                        <li>
                                            The right to receive an accounting of disclosures of your health information,{' '}
                                            <strong>
                                                except those disclosures related to treatment, payment or health operations. The exception
                                                also covers disclosures previously listed in this disclosure that do not require your
                                                authorization,
                                            </strong>
                                        </li>
                                        <li>The right to receive a copy of this notice in writing.</li>
                                    </ol>
                                </Typography>
                            </Grid>
                            <Divider />
                            <Grid item>
                                <Typography align="justify" fontSize={16}>
                                    We have the following obligations:
                                </Typography>
                                <Typography variant="body1">
                                    <ol align="justify">
                                        <li>
                                            We are required by law to maintain the privacy of your Protected Health Information, to provide
                                            you with a notice of our legal duties and Notice of Privacy Practices and obtain a written
                                            acknowledgement that it has been provided to you.
                                        </li>
                                        <li>
                                            We are required to abide by the terms of the notice, including privacy agreements with Trading
                                            Partners and/or Business Associates with whom we contract or do business and by maintaining
                                            physical, electronic and work practice controls that comply with suggested federal regulations
                                            to guard your PHI.
                                        </li>
                                        <li>
                                            We are required to advise you of any changes we make in the terms of our Notice of Privacy
                                            Practices by posting the amended Notice and make a copy available to you upon request.
                                        </li>
                                    </ol>
                                </Typography>
                            </Grid>
                            <Divider />
                            <Grid item>
                                <Typography align="justify" fontSize={16}>
                                    Complaints and Requests:
                                </Typography>
                                <br />
                                <Typography variant="body1">
                                    If you feel we have violated your privacy rights, have questions concerning our Notice of Privacy
                                    Practices or you wish to make a request, you may contact the Vital eCare Privacy Officer, at the
                                    following address:{' '}
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item>
                                <Typography color="dark" variant="body1" align="justify">
                                    <strong>
                                        <strong>Vital eCare</strong>
                                    </strong>
                                    <br />
                                    Vital eCare Address
                                </Typography>
                            </Grid>
                            {/* 
                            <p>or with the federal agency in charge of enforcing patient&#39;s privacy rights. That address is:</p>
                            <address sx={{ mt: 10 }}>
                                <strong>
                                    <strong>Office for Civil Rights, U.S. Department of Health and Human Services,</strong>
                                </strong>
                                <br />
                                200 Independence Avenue, S.W., Room 509F, HHS Building, Washington, D.C.20201
                            </address> */}
                        </Grid>

                        <br />
                        <br />
                        <Grid Continer xs={12} spacing={1}>
                            <Grid item Xs={12}>
                                <FormControlLabel
                                    control={<Checkbox defaultChecked />}
                                    label={
                                        <>
                                            Provision of essential services (e.g., logging in, viewing information, using services etc.
                                            (Required in order to use Vital eCare’s services))
                                        </>
                                    }
                                />
                            </Grid>
                            <Grid item Xs={12}>
                                <FormControlLabel control={<Checkbox />} label={<>Improving performance of the system</>} />
                            </Grid>
                            <Grid item Xs={12}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label={<>Providing services and information relevant to the user</>}
                                />
                            </Grid>
                            <Grid item Xs={12}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label={
                                        <>Selling or otherwise making their information available to third parties for marketing purpose</>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 3 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Button
                                        fullWidth
                                        // size="small"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => navigate('/dashboard')}
                                    >
                                        <FormattedMessage id="I_agree_and_sign_up" />
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button variant="outlined" color="primary" fullWidth onClick={handleClick}>
                                        <FormattedMessage id="I_do_not_agree" />
                                    </Button>
                                </Grid>
                                {open && (
                                    <div>
                                        <Dialog
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                            sx={{ p: 3 }}
                                        >
                                            {open && (
                                                <>
                                                    <DialogTitle id="alert-dialog-title">Privacy Policy</DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                            <Typography variant="body2" component="span">
                                                                You will not be able to use the Vital eCare services without accepting the
                                                                Privacy Policy.
                                                            </Typography>
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions style={{ justifyContent: 'space-between' }} sx={{ pl: 2.5 }}>
                                                        <Button variant="contained" size="small" onClick={handleClose} autoFocus>
                                                            Ok
                                                        </Button>
                                                    </DialogActions>
                                                </>
                                            )}
                                        </Dialog>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </PerfectScrollbar>
            </Grid>
        </Grid>
    );
};

export default PrivacyPolicyCard;
