import { Chip, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AppointmentsTab from '../appointmentstab';
import { useLocation, useNavigate } from 'react-router-dom';
import HealthRecordsBreadCrumbs from 'views/healthrecords/HealthRecordsBreadCrumbs';
import { gridSpacing } from 'store/constant';
// import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import KeyboardArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardArrowLeftTwoTone';

export default function ExpansionCard() {
    const theme = useTheme();
    // const { value } = route.params;
    const location = useLocation();
    const navigate = useNavigate();
    const user = location.state;
    const value = user;
    const menuItems = {
        items: [
            {
                id: 'sample-docs-roadmap',
                type: 'group',
                children: [
                    {
                        id: 'appointments',
                        title: 'Appointments',
                        type: 'collapse',
                        url: '/healthrecords/appointments',
                        breadcrumbs: true,
                        children: [
                            {
                                id: 'appointmentdetails',
                                title: 'Appointment Details',
                                type: 'item',
                                url: '/healthrecords/appointments/appointmentdetails',
                                breadcrumbs: true
                            }
                        ]
                    }
                ]
            }
        ]
    };

    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12} lg={12}>
                    <HealthRecordsBreadCrumbs
                        navigation={menuItems}
                        home="/healthrecords"
                        homeTitle="Health Records"
                        separator={NavigateNextIcon}
                        sx={{
                            mb: '0px !important',
                            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50'
                        }}
                    />
                </Grid>

                <Grid container direction="column" sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton onClick={() => navigate('/healthrecords/appointments')} size="small">
                                <KeyboardArrowLeftTwoToneIcon size="large" sx={{ fontSize: '25px' }} />
                            </IconButton>
                            <Typography variant="h3">Appointment Details on 05/06/2022 07:30 AM [PST]</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            <MainCard
                content={false}
                sx={{
                    p: 2,
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
                    border: '1px solid',
                    borderColor: theme.palette.mode === 'dark' ? 'transparent' : '#e9e8e8',

                    '&:hover': {
                        border: `1px solid${theme.palette.primary.main}`
                    }
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Chip label="Office Visit" size="small" color="info" />
                        <Typography align="left" variant="h4" component="div" pt={1}>
                            05/06/2022 07:30 AM [PST] &nbsp;
                            <Chip
                                label="Completed"
                                size="small"
                                sx={{
                                    color: theme.palette.success.main,
                                    bgcolor: theme.palette.success.light
                                }}
                            />
                        </Typography>
                        <Typography align="left" variant="body2" sx={{ pt: 1 }}>
                            <>
                                <b>Dr. Roger Miles - Sutter Health,</b> San Antonio, Texas
                            </>
                        </Typography>
                        <Typography align="left" variant="body2" sx={{ pt: 1 }}>
                            <>
                                <b>Orthopedics</b>
                            </>
                        </Typography>
                        <Typography align="left" variant="body2" sx={{ pt: 1 }}>
                            Illness
                        </Typography>
                    </Grid>
                </Grid>
            </MainCard>

            <Grid item xs={12}>
                <AppointmentsTab value1={value} />
            </Grid>
        </>
    );
}
