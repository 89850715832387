import {
    useTheme,
    Grid,
    Typography,
    Button,
    FormControl,
    TextField,
    Stack,
    InputLabel,
    Autocomplete,
    Select,
    MenuItem
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import HealthRecordsBreadCrumbs from '../HealthRecordsBreadCrumbs';
import { FormattedMessage } from 'react-intl';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MedicationTable from './MedicationTable';
import { useState } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';

const Medication = () => {
    const theme = useTheme();
    const [show, setShow] = useState(false);
    const [currency, setCurrency] = useState('1');
    const [prescribedDate, setPrescribedDate] = useState(null);
    const [route, setRoute] = useState(null);
    const [quantity, setQuantity] = useState('1');
    const [frequency, setFrequency] = useState(null);
    const [physician, setPhysician] = useState(null);
    const handleSelectChange = (event) => {
        setCurrency(event.target.value);
    };
    const handleRouteChange = (event) => {
        setRoute(event.target.value);
    };
    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };
    const handleFrequencyChange = (event) => {
        setFrequency(event.target.value);
    };
    const handlePhysicianChange = (event) => {
        setPhysician(event.target.value);
    };
    const menuItems = {
        items: [
            {
                id: 'sample-docs-roadmap',
                type: 'group',
                children: [
                    {
                        id: 'medication',
                        title: 'Medication',
                        type: 'item',
                        url: '/healthrecords/medication',
                        breadcrumbs: true
                    }
                ]
            }
        ]
    };
    const reviewState = [
        {
            value: '1',
            label: 'Active'
        },
        {
            value: '2',
            label: 'Inactive'
        }
    ];
    const routeState = ['Oral'];
    const physicianState = ['Dr. Roger Miles', ' Dr. Frank Ellison'];
    const quantityState = ['1/4', '1/2', '1', '1 1/2', '2', '3', '4', '5', '6', '7', '8', '9'];
    const frequencyState = [
        '1 time',
        'After meals',
        'Before meals',
        'Every 8 hours',
        'Every day',
        'Every other day',
        'Four times/day',
        'Three times/day',
        'Twice/day'
    ];
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };
    const cardStyle = {
        pt: '15px',
        mt: '10px',
        ml: '25px',
        mr: '5px',
        pb: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12} lg={12}>
                    <HealthRecordsBreadCrumbs
                        navigation={menuItems}
                        home="/healthrecords"
                        homeTitle="Health Records"
                        separator={NavigateNextIcon}
                        sx={{
                            mb: '0px !important',
                            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Stack direction="row" spacing={2}>
                        <Typography variant="h3">
                            <FormattedMessage id="Medication" />
                        </Typography>
                        <Button size="small" variant="contained" onClick={() => setShow((prev) => !prev)}>
                            <FormattedMessage id="Add_Medication" />
                        </Button>
                    </Stack>
                </Grid>
                {show && (
                    <Grid item xs={12} md={12} lg={12} sx={cardStyle}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Typography variant="h4"> Add Medication </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <TextField fullWidth id="medication" label="Medication" size="small" />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <TextField select fullWidth id="format" label="Format" size="small" />
                            </Grid>

                            <Grid item xs={12} md={3} lg={3}>
                                <TextField fullWidth id="dosage" label="Dosage" size="small" />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3} sx={{ pr: 3 }}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="route">Route</InputLabel>
                                    <Select
                                        id="route"
                                        MenuProps={MenuProps}
                                        value={route}
                                        size="small"
                                        label="Route"
                                        fullWidth
                                        onChange={handleRouteChange}
                                    >
                                        {routeState.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                {/* <TextField fullWidth id="severity" label="severity" size="small" /> */}
                                <FormControl fullWidth size="small">
                                    <InputLabel id="quantity">Quantity</InputLabel>
                                    <Select
                                        id="quantity"
                                        MenuProps={MenuProps}
                                        value={quantity}
                                        size="small"
                                        label="Quantity"
                                        fullWidth
                                        onChange={handleQuantityChange}
                                    >
                                        {quantityState.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3} lg={3}>
                                {/* <TextField fullWidth id="severity" label="severity" size="small" /> */}
                                <FormControl fullWidth size="small">
                                    <InputLabel id="frequency">Frequency</InputLabel>
                                    <Select
                                        id="frequency"
                                        MenuProps={MenuProps}
                                        value={frequency}
                                        size="small"
                                        label="Frequency"
                                        fullWidth
                                        onChange={handleFrequencyChange}
                                    >
                                        {frequencyState.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3} lg={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                        label="Prescribed Date"
                                        value={prescribedDate}
                                        onChange={(newValue) => {
                                            setPrescribedDate(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3} sx={{ pr: 3 }}>
                                <TextField
                                    id="status"
                                    select
                                    label="Status"
                                    value={currency}
                                    size="small"
                                    fullWidth
                                    onChange={handleSelectChange}
                                >
                                    {reviewState.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <TextField fullWidth id="manufacturer" label="Manufacturer" size="small" />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <TextField fullWidth id="reason" label="Reason" size="small" />
                            </Grid>
                            {/* <Grid item xs={3}>
                                <TextField fullWidth id="physician" label="Physician" size="small" />
                            </Grid> */}
                            {/* <Grid item xs={3}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="route">Physician</InputLabel>
                                    <Select
                                        id="physician"
                                        MenuProps={MenuProps}
                                        value={physician}
                                        size="small"
                                        label="Physician"
                                        fullWidth
                                        onChange={handlePhysicianChange}
                                    >
                                        {physicianState.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid> */}
                            {/*  <Grid item xs={3}>
                                <TextField fullWidth id="source" label="Source" size="small" />
                            </Grid> */}
                            {/* <Grid item xs={3} sx={{ pr: 3 }}>
                                <TextField fullWidth id="intent" label="Intent" size="small" />
                            </Grid> */}
                            <Grid item xs={12} sx={{ pr: 3 }}>
                                {/* <TextareaAutosize aria-label="empty textarea" placeholder="Note" style={{ width: 400, height: 100 }} /> */}
                                <TextField size="small" fullWidth label="Note" />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <Button variant="contained" onClick={() => setShow((prev) => !prev)}>
                                        Save
                                    </Button>

                                    <Button variant="outlined" onClick={() => setShow((prev) => !prev)}>
                                        Close
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12} md={12} lg={12}>
                    <MedicationTable />
                </Grid>
            </Grid>
        </>
    );
};

export default Medication;
