import { useDispatch } from 'store';
import { useNavigate } from 'react-router-dom';
// material-ui
import { Button, FormControl, FormHelperText, Grid, InputLabel, Select, MenuItem, TextField } from '@mui/material';

// project imports

import { openSnackbar } from 'store/slices/snackbar';
import { gridSpacing } from 'store/constant';

// import SecurityQuestionsInnerCardCopy from './SecurityQuestionInnerCardCopy';

// third-party
import { Formik } from 'formik';

import * as yup from 'yup';

/**
 * 'Enter your age'
 * yup.number Expected 0 arguments, but got 1 */
// const validationSchema = yup.object({
//     sq1: yup.string().required('Security Question1 is required.'),
//     sq2: yup.string().required('Security Question2 is required.'),
//     sq3: yup.string().required('Security Question3 is required.')
// });

// ==============================|| FORM VALIDATION - LOGIN FORMIK  ||============================== //

const ForgotPasswordSecurityQuestionInnerCard = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    return (
        <>
            <Formik
                initialValues={{
                    sq1: '',
                    sq2: '',
                    sq3: '',
                    ans: ''
                }}
                validationSchema={yup.object({
                    sq1: yup.string().required('Security Question1 is required.'),
                    sq2: yup.string().required('Security Question2 is required.'),
                    sq3: yup.string().required('Security Question3 is required.'),
                    ans: yup.string().required('Answer is required.')
                })}
                onSubmit={() => {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Select - Submit Success',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                }}
            >
                {({ errors, handleChange, handleSubmit, handleBlur, touched, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="sq1-select">Security Question</InputLabel>
                                    <Select
                                        labelId="sq1-select"
                                        id="sq1"
                                        name="sq1"
                                        value={values.sq1}
                                        onChange={handleChange}
                                        label="Security Question"
                                        // selected="What is the name of your pet"
                                    >
                                        <MenuItem value="sq1">What was the name of your first pet?</MenuItem>
                                        <MenuItem value="sq2">SQ 2</MenuItem>
                                        <MenuItem value="sq3">SQ 3</MenuItem>
                                    </Select>
                                </FormControl>

                                {errors.sq1 && (
                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                        {' '}
                                        {errors.sq1}{' '}
                                    </FormHelperText>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id="answer"
                                    name="answer"
                                    label="Answer"
                                    // value={values.ans1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.ans && Boolean(errors.ans)}
                                    helperText={touched.ans && errors.ans}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    onClick={() => navigate('../patientforgotpassword/ResetPassword')}
                                >
                                    Next
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default ForgotPasswordSecurityQuestionInnerCard;
