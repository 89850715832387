import MainCard from 'ui-component/cards/MainCard';
import ProxyUserList from '../ProxyUserList';
import { Card, useTheme, Grid, Divider, Typography, Button, CardContent } from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';
import MuiTypography from '@mui/material/Typography';
import TodayImg from './todaysplan.svg';

const TodaysPlan = () => {
    const theme = useTheme();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#e9e8e8'
    };
    return (
        <MainCard title="Todays Activities" content={false}>
            <Grid container sx={{ backgroundColor: theme.palette.cardColor.dashboardCardContentBg }}>
                <Grid item xs={8}>
                    <Grid container>
                        <Grid item sx={{ pt: 6, pl: 2 }}>
                            <Typography variant="dashboardCardContent">No Activities are organized for today.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container>
                        <Grid item sx={{ pt: 3, pb: 2 }}>
                            <img src={TodayImg} alt="TodaysPlan" width="115" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default TodaysPlan;
