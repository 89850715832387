// material-ui
import { Table, TableBody, Grid, TableContainer, TableHead, TableRow, Card, useTheme } from '@mui/material';
import MuiTypography from '@mui/material/Typography';
// project imports
import SubCard from 'ui-component/cards/SubCard';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.common.white,
        width: '15%'
    }
    /*  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    }, */
}));

// ==============================|| TABLE - DENSE ||============================== //

export default function VitalSignsTable() {
    const theme = useTheme();
    /*   const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#e9e8e8',
        minWidth: 600
    }; */
    return (
        <SubCard>
            <Grid item>
                <MuiTypography variant="h4" color={theme.palette.text.dark} gutterBottom>
                    Vital Signs
                </MuiTypography>
            </Grid>
            <TableContainer sx={{ border: '1px solid', borderColor: 'Black' }}>
                <Table size="small" aria-label="a dense table">
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Date/Time
                        </StyledTableCell>
                        <TableCell align="left">11/10/2021 01:25 AM PST</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Height
                        </StyledTableCell>
                        <TableCell align="left">178 cm</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Weight
                        </StyledTableCell>
                        <TableCell align="left">74.39 kg</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Blood Pressure
                        </StyledTableCell>
                        <TableCell align="left">135/75</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="left" variant="head">
                            Body Mass Index
                        </StyledTableCell>
                        <TableCell align="left">23.53</TableCell>
                    </TableRow>
                </Table>
            </TableContainer>
        </SubCard>
    );
}
