// material-ui
import { createTheme } from '@mui/material/styles';

// assets
import defaultColor from 'assets/scss/_themes-vars.module.scss';
import theme1 from 'assets/scss/_theme1.module.scss';
import theme2 from 'assets/scss/_theme2.module.scss';
import theme3 from 'assets/scss/_theme3.module.scss';
import theme4 from 'assets/scss/_theme4.module.scss';
import theme5 from 'assets/scss/_theme5.module.scss';
import theme6 from 'assets/scss/_theme6.module.scss';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (navType, presetColor) => {
    let colors;
    switch (presetColor) {
        case 'theme1':
            colors = theme1;
            break;
        case 'theme2':
            colors = theme2;
            break;
        case 'theme3':
            colors = theme3;
            break;
        case 'theme4':
            colors = theme4;
            break;
        case 'theme5':
            colors = theme5;
            break;
        case 'theme6':
            colors = theme6;
            break;
        case 'default':
        default:
            colors = defaultColor;
    }

    return createTheme({
        palette: {
            mode: navType,
            common: {
                black: colors.darkPaper,
                grey: colors.grey300
            },
            primary: {
                light: navType === 'dark' ? colors.darkPrimaryLight : colors.paper,
                paper: navType === 'dark' ? colors.darkPrimaryLight : colors.primaryLight,
                main: navType === 'dark' ? colors.darkPrimaryMain : colors.primaryMain,
                dark: navType === 'dark' ? colors.darkPrimaryDark : colors.primaryDark,
                100: navType === 'dark' ? colors.darkPrimary200 : colors.primary100,
                200: navType === 'dark' ? colors.darkPrimary200 : colors.primary200,
                800: navType === 'dark' ? colors.darkPrimary800 : colors.primary800
            },
            secondary: {
                light: navType === 'dark' ? colors.darkSecondaryLight : colors.secondaryLight,
                main: navType === 'dark' ? colors.darkSecondaryMain : colors.secondaryMain,
                dark: navType === 'dark' ? colors.darkSecondaryDark : colors.primaryMain,
                200: navType === 'dark' ? colors.darkSecondary200 : colors.secondary200,
                800: navType === 'dark' ? colors.darkSecondary800 : colors.secondary800,
                secondary1: navType === 'dark' ? colors.secondary1 : colors.secondary1,
                secondary2: navType === 'dark' ? colors.secondary2 : colors.secondary2,
                secondary3: navType === 'dark' ? colors.secondary3 : colors.secondary3,
                secondary4: navType === 'dark' ? colors.secondary4 : colors.secondary4,
                secondary5: navType === 'dark' ? colors.secondary5 : colors.secondary5
            },
            tertiary: {
                lightBlue: navType === 'dark' ? colors.tertiaryLightBlue : colors.tertiaryLightBlue,
                tertiary1: navType === 'dark' ? colors.tertiary1 : colors.tertiary1,
                tertiary2: navType === 'dark' ? colors.tertiary2 : colors.tertiary2,
                tertiary3: navType === 'dark' ? colors.tertiary3 : colors.tertiary3,
                tertiary4: navType === 'dark' ? colors.tertiary4 : colors.tertiary4
            },
            dreams: {
                diet: {
                    primary: navType === 'dark' ? colors.dietPrimary : colors.dietPrimary,
                    secondary: navType === 'dark' ? colors.dietSecondary : colors.dietSecondary
                },
                rest: {
                    primary: navType === 'dark' ? colors.restPrimary : colors.restPrimary,
                    secondary: navType === 'dark' ? colors.restSecondary : colors.restSecondary
                },
                exercise: {
                    primary: navType === 'dark' ? colors.exercisePrimary : colors.exercisePrimary,
                    secondary: navType === 'dark' ? colors.exerciseSecondary : colors.exerciseSecondary
                },
                attitude: {
                    primary: navType === 'dark' ? colors.attitudePrimary : colors.attitudePrimary,
                    secondary: navType === 'dark' ? colors.attitudeSecondary : colors.attitudeSecondary
                },
                medication: {
                    primary: navType === 'dark' ? colors.medicationPrimary : colors.medicationPrimary,
                    secondary: navType === 'dark' ? colors.medicationSecondary : colors.medicationSecondary
                },
                specialist: {
                    primary: navType === 'dark' ? colors.specialistPrimary : colors.specialistPrimary,
                    secondary: navType === 'dark' ? colors.specialistSecondary : colors.specialistSecondary
                }
            },
            error: {
                light: colors.errorLight,
                main: colors.errorMain,
                dark: colors.errorDark
            },
            orange: {
                light: colors.orangeLight,
                main: colors.orangeMain,
                dark: colors.orangeDark
            },
            warning: {
                light: colors.warningLight,
                main: colors.warningMain,
                dark: colors.warningDark
            },
            success: {
                light: colors.successLight,
                200: colors.success200,
                main: colors.successMain,
                dark: colors.successDark
            },
            grey: {
                50: colors.grey50,
                100: colors.grey100,
                500: navType === 'dark' ? colors.darkTextSecondary : colors.grey500,
                600: navType === 'dark' ? colors.darkTextTitle : colors.grey900,
                700: navType === 'dark' ? colors.darkTextPrimary : colors.grey700,
                900: navType === 'dark' ? colors.darkTextPrimary : colors.grey900
            },
            dark: {
                light: colors.darkTextPrimary,
                main: colors.darkLevel1,
                dark: colors.darkLevel2,
                800: colors.darkBackground,
                900: colors.darkPaper
            },
            text: {
                primary: navType === 'dark' ? colors.darkTextPrimary : colors.grey700,
                secondary: navType === 'dark' ? colors.darkTextSecondary : colors.grey500,
                dark: navType === 'dark' ? colors.darkTextPrimary : colors.grey900,
                hint: colors.grey100,
                paper: navType === 'dark' ? colors.darkLevel2 : colors.paper
            },
            divider: navType === 'dark' ? colors.darkTextPrimary : colors.grey200,
            background: {
                paper: navType === 'dark' ? colors.darkLevel2 : colors.paper,
                default: navType === 'dark' ? colors.darkPaper : colors.paper,
                menu: navType === 'dark' ? colors.darkPaper : colors.gradient,
                backgroundGradient: navType === 'dark' ? colors.backgroundGradient : colors.backgroundGradient,
                headerBg: navType === 'dark' ? colors.headerBg : colors.headerBg
            },
            iconColor: {
                todaysplanwithtime: navType === 'dark' ? colors.todaysplanwithtime : colors.todaysplanwithtime,
                todaysplanwithouttime: navType === 'dark' ? colors.todaysplanwithouttime : colors.todaysplanwithouttime
            },
            cardColor: {
                dashboardCardContentBg: navType === 'dark' ? colors.dashboardCardContentBg : colors.dashboardCardContentBg,
                dashboardCardHeaderBg: navType === 'dark' ? colors.dashboardCardHeaderBg : colors.dashboardCardHeaderBg,
                mainCardContentsBg: navType === 'dark' ? colors.mainCardContentsBg : colors.paper
            },
            tableColor: {
                tableHeaderBg: navType === 'dark' ? colors.tableHeaderBg : colors.tableHeaderBg,
                tableRowBg: navType === 'dark' ? colors.tableRowBg : colors.tableRowBg,
                tableExpandBg: navType === 'dark' ? colors.tableExpandBg : colors.tableExpandBg
            },
            black: {
                black: navType === 'dark' ? colors.black : colors.black
            }
        }
    });
};

export default Palette;
