import { useTheme } from '@emotion/react';
import { LocalizationProvider, PickersDay, pickersDayClasses, StaticDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import addDays from 'date-fns/addDays';
import isSameDay from 'date-fns/isSameDay';
import PropTypes from 'prop-types';

export default function CalendarCard({ handleChangeDate, dateValue }) {
    const birthday = addDays(new Date(), 1);
    const calenderEventsStyle = {
        border: '1px solid #2196f3',
        margin: '1px'
    };

    const highlightedDays = [
        {
            date: birthday,
            styles: calenderEventsStyle
        },
        {
            date: addDays(new Date(), 2),
            styles: calenderEventsStyle
        }
    ];
    const DATE_SIZE = 50;
    const theme = useTheme();
    const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
        const matchedStyles = highlightedDays.reduce((a, v) => (isSameDay(date, v.date) ? v.styles : a), {});

        return (
            <PickersDay
                {...pickersDayProps}
                sx={{
                    ...matchedStyles,
                    [`&&.${pickersDayClasses.selected}`]: {
                        // backgroundColor: 'green'
                    }
                }}
            />
        );
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box
                sx={{
                    '& > div': {
                        minWidth: '100%',
                        color: theme.palette.secondary.main,
                        backgroundColor: theme.palette.primary.light
                    },
                    '& > div > div, & > div > div > div, & .MuiCalendarPicker-root': {
                        width: '100%',
                        overflowY: 'hidden'
                    },
                    '& > div > div, & > div > div > div, & .MuiCalendarPicker-viewTransitionContainer': {
                        overflowY: 'hidden'
                    },
                    '& .MuiTypography-caption': {
                        width: DATE_SIZE,
                        margin: 0,
                        fontSize: '1rem',
                        fontWeight: '1px',
                        color: theme.palette.secondary.main
                    },
                    '& .PrivatePickersSlideTransition-root': {
                        minHeight: DATE_SIZE * 6
                    },
                    '& .PrivatePickersSlideTransition-root [role="row"]': {
                        margin: 0
                    },
                    '& .MuiPickersDay-dayWithMargin': {
                        margin: 0
                    },
                    '& .MuiPickersDay-root': {
                        width: DATE_SIZE,
                        height: DATE_SIZE,
                        color: theme.palette.secondary.main,
                        backgroundColor: theme.palette.primary.light,
                        margin: '1px'
                    }
                }}
            >
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    // variant="inline"
                    value={dateValue}
                    renderDay={renderWeekPickerDay}
                    // onChange={(newValue) => {
                    //     setDateValue(newValue) }}
                    onChange={(newValue) => handleChangeDate(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth required />}
                />
            </Box>
        </LocalizationProvider>
    );
}
CalendarCard.propTypes = {
    handleChangeDate: PropTypes.func,
    dateValue: PropTypes.object
};
