import { Card, Grid, Typography, useMediaQuery, Stack } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

// =============================|| SIDE ICON CARD ||============================= //

const SideIconCard = ({
    iconPrimary,
    iconPrimaryName,
    primary,
    primarySub,
    secondary,
    secondarySub,
    color,
    bgcolor,
    iconBgColor,
    superscript,
    units
}) => {
    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));

    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary !== undefined ? <IconPrimary /> : null;

    return (
        <Card sx={{ bgcolor: bgcolor || '', position: 'relative', border: `1px solid ${theme.palette.common.grey}` }}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid
                    item
                    xs={4}
                    sx={{
                        background: iconBgColor,
                        py: 1.5,
                        px: 0
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: 'center',
                            color: theme.palette.primary.light,
                            '& > svg': {
                                width: 20,
                                height: 20
                            }
                        }}
                        align="center"
                    >
                        <Grid container direction="column" justifyContent="space-between" spacing={0.5} alignItems="center">
                            <Grid item sm={2}>
                                {primaryIcon}
                            </Grid>
                            <Grid item sm={2}>
                                {iconPrimaryName}
                            </Grid>
                            <Grid item sm={2}>
                                {units}
                            </Grid>
                        </Grid>
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Grid container justifyContent="space-between" sx={{ pr: 3 }}>
                        <Grid item sm={6}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="space-between"
                                spacing={1}
                                alignItems={matchDownXs ? 'center' : 'flex-start'}
                            >
                                <Grid item sm={2}>
                                    <Typography variant="h5" color="secondary" sx={{ ml: 2 }}>
                                        <b>{primary}</b>
                                    </Typography>
                                </Grid>
                                <Grid item sm={2}>
                                    <Typography variant="h3" align="left" color="secondary" sx={{ ml: 2 }}>
                                        {primarySub}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} alignItems="flex-end">
                            <Grid
                                container
                                direction="column"
                                justifyContent="space-between"
                                spacing={1}
                                alignItems={matchDownXs ? 'center' : 'flex-end'}
                            >
                                <Grid item sm={2}>
                                    <Typography variant="h5" color="secondary" sx={{ ml: 2 }}>
                                        <b>{secondary}</b>
                                    </Typography>
                                </Grid>
                                <Grid item sm={2}>
                                    <Stack direction="row">
                                        <Typography variant="h3" align="left" color="secondary" sx={{ ml: 2 }}>
                                            {secondarySub}
                                        </Typography>
                                        {superscript && (
                                            <Typography sx={{ color: theme.palette.grey[700] }}>
                                                <b>*</b>
                                            </Typography>
                                        )}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Grid item xs={8}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        spacing={1}
                        alignItems={matchDownXs ? 'center' : 'flex-start'}
                    >
                        <Grid item sm={12}>
                            <Typography variant="h3" sx={{ color: bgcolor ? '#000000' : '', ml: 2 }}>
                                {primary}
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography variant="body2" align="left" sx={{ color: bgcolor ? '#fff' : 'grey.700', ml: 2 }}>
                                {secondary} <span style={{ color }}>{secondarySub}</span>{' '}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
        </Card>
    );
};

SideIconCard.propTypes = {
    iconPrimary: PropTypes.object,
    iconPrimaryName: PropTypes.string,
    primary: PropTypes.string,
    primarySub: PropTypes.string,
    secondary: PropTypes.string,
    secondarySub: PropTypes.string,
    color: PropTypes.string,
    bgcolor: PropTypes.string
};

export default SideIconCard;
