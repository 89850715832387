// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconDashboard,
    IconHeart,
    IconMail,
    IconSettings,
    IconBuildingHospital,
    IconFile,
    IconUsers
} from '@tabler/icons';

import { ReactComponent as DREAMSDefault } from '../assets/images/icons/dreams/DREAMS_default.svg';
import { ReactComponent as DREAMSActive } from '../assets/images/icons/dreams/DREAMS_active.svg';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import ChatIcon from '@mui/icons-material/Chat';
import GroupsIcon from '@mui/icons-material/Groups';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import TaskIcon from '@mui/icons-material/Task';

// constant
const icons = {
    IconBrandChrome,
    IconDashboard,
    IconHelp,
    IconSitemap,
    IconHeart,
    IconMail,
    IconSettings,
    IconBuildingHospital,
    IconFile,
    IconUsers,
    DREAMSDefault,
    DREAMSActive,
    CreateNewFolderIcon,
    ChatIcon,
    GroupsIcon,
    BeenhereIcon,
    SettingsIcon,
    HelpIcon,
    SpeedOutlinedIcon,
    TaskIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.SpeedOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'healthrecords',
            title: <FormattedMessage id="Health_Records" />,
            type: 'item',
            url: '/healthrecords',
            icon: icons.CreateNewFolderIcon,
            breadcrumbs: false
        },
        {
            id: 'dreams',
            title: <FormattedMessage id="Dreams" />,
            type: 'item',
            url: '/dreams',
            icon: icons.DREAMSDefault,
            activeIcon: icons.DREAMSActive,
            breadcrumbs: false
        },
        {
            id: 'tasks',
            title: 'Tasks',
            type: 'item',
            url: '/tasks',
            icon: icons.TaskIcon,
            breadcrumbs: false
        },
        {
            id: 'messages',
            title: <FormattedMessage id="Messages" />,
            type: 'item',
            url: '/messages',
            icon: icons.ChatIcon,
            breadcrumbs: false
            // chip: {
            //     label: '1',
            //     color: 'error',
            //     size: 'small'
            // }
        },
        {
            id: 'careteam',
            title: <FormattedMessage id="Care_Team" />,
            type: 'item',
            url: '/careteam',
            icon: icons.GroupsIcon,
            breadcrumbs: false
        },

        {
            id: 'connectprovider',
            title: <FormattedMessage id="Download_Health_Records" />,
            type: 'item',
            url: '/connectprovider',
            icon: icons.BeenhereIcon,
            breadcrumbs: false
        },
        {
            id: 'accountsettings',
            title: <FormattedMessage id="Account_Settings" />,
            type: 'item',
            url: '/accountsettings',
            icon: icons.SettingsIcon,
            breadcrumbs: false
        },
        {
            id: 'howitworks',
            title: <FormattedMessage id="how_it_works" />,
            type: 'item',
            url: '/howitworks',
            icon: icons.HelpIcon,
            breadcrumbs: false
        }
    ]
};

export default other;
