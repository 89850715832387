import { useNavigate } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
// material-ui

import { Button, Grid, TextField } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports

import useAuth from 'hooks/useAuth';
import useScriptRef from 'hooks/useScriptRef';
import CaptchaPage from 'views/patientonboarding/PatientCaptcha';

// import { strengthColor, strengthIndicatorNumFunc } from 'utils/password-strength';

// ============================|| PATIENT - SIGNUP ||============================ //

const ProxyCredentialsVerificationCard = ({ ...others }) => {
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    // const [strength, setStrength] = React.useState(0);
    // const [level, setLevel] = React.useState();

    const { patientUsernamePasswordSignIn } = useAuth();

    // const changePassword = (value) => {
    //     const temp = strengthIndicatorNumFunc(value);
    //     setStrength(temp);
    //     setLevel(strengthColor(temp));
    // };
    // useEffect(() => {
    //     changePassword('123456');
    // }, []);

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Formik
                    initialValues={{
                        username: '',
                        password: '',
                        confirmPassword: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string()
                            .min(1, 'Mininum 1 characters')
                            .max(15, 'Maximum 15 characters')

                            .required('Username is required'),

                        password: Yup.string().max(255).required('Password is required'),
                        confirmPassword: Yup.string().when('password', {
                            is: (val) => !!(val && val.length > 0),
                            then: Yup.string().oneOf([Yup.ref('password')], 'Both Password must be match!')
                        })
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            await patientUsernamePasswordSignIn(values.username, values.password).then(
                                () => {},
                                (err) => {
                                    if (scriptedRef.current) {
                                        setStatus({ success: false });
                                        setErrors({ submit: err.message });
                                        setSubmitting(false);
                                    }
                                }
                            );
                        } catch (err) {
                            console.error(err);
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit} {...others}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Grid item xs={12} pb={2}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            id="outlined-default-name"
                                            placeholder="First Name"
                                            label="First Name"
                                            defaultValue="Gloria"
                                        />
                                    </Grid>

                                    <Grid item xs={12} pb={2}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            id="outlined-default-name"
                                            placeholder="Last Name"
                                            label="Last Name"
                                            defaultValue="Hailey"
                                        />
                                    </Grid>
                                    <Grid item xs={12} pb={2}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label="Email"
                                            id="outlined-default-email"
                                            placeholder="email"
                                            defaultValue="gloria@mail.com"
                                        />
                                    </Grid>
                                    <Grid item xs={12} pb={2}>
                                        <TextField
                                            size="small"
                                            label="Secondary Email"
                                            fullWidth
                                            id="outlined-default-email"
                                            placeholder="Secondary Email"
                                        />
                                    </Grid>
                                    <Grid item xs={12} pb={2} align="center">
                                        <b>Or</b>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            label="Mobile"
                                            fullWidth
                                            id="outlined-default-mobile"
                                            defaultValue="+1(141)548-2627"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <CaptchaPage />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        // size="small"
                                        type="submit"
                                        color="primary"
                                        onClick={() => navigate('../proxyonboarding/ProxySignUp')}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>

                            {/* {strength !== 0 && (
                                <FormControl fullWidth>
                                    <Box sx={{ mb: 2 }}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                                <Box
                                                    style={{ backgroundColor: level?.color }}
                                                    sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" fontSize="0.75rem">
                                                    {level?.label}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </FormControl>
                            )} */}
                        </form>
                    )}
                </Formik>
            </Grid>
        </>
    );
};

export default ProxyCredentialsVerificationCard;
