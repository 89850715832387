import { useDispatch } from 'store';
import { useNavigate } from 'react-router-dom';
// material-ui
import { Button, FormControl, FormHelperText, Grid, InputLabel, Select, MenuItem, TextField } from '@mui/material';

// project imports

import { openSnackbar } from 'store/slices/snackbar';
import { gridSpacing } from 'store/constant';

// import SecurityQuestionsInnerCardCopy from './SecurityQuestionInnerCardCopy';

// third-party
import { Formik } from 'formik';

import * as yup from 'yup';

/**
 * 'Enter your age'
 * yup.number Expected 0 arguments, but got 1 */
// const validationSchema = yup.object({
//     sq1: yup.string().required('Security Question1 is required.'),
//     sq2: yup.string().required('Security Question2 is required.'),
//     sq3: yup.string().required('Security Question3 is required.')
// });

// ==============================|| FORM VALIDATION - LOGIN FORMIK  ||============================== //

const ProxySecurityQuestionInnerCard = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    return (
        <>
            <Formik
                initialValues={{
                    sq1: '',
                    sq2: '',
                    sq3: '',
                    ans1: '',
                    ans2: '',
                    ans3: ''
                }}
                validationSchema={yup.object({
                    sq1: yup.string().required('Security Question1 is required.'),
                    sq2: yup.string().required('Security Question2 is required.'),
                    sq3: yup.string().required('Security Question3 is required.'),
                    ans1: yup.string().required('Answer 1 is required.'),
                    ans2: yup.string().required('Answer 2 is required.'),
                    ans3: yup.string().required('Answer 3 is required.')
                })}
                onSubmit={() => {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Select - Submit Success',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                }}
            >
                {({ errors, handleChange, handleSubmit, handleBlur, touched, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="sq1-select">Security Question 1</InputLabel>
                                    <Select
                                        labelId="sq1-select"
                                        id="sq1"
                                        name="sq1"
                                        value={values.sq1}
                                        onChange={handleChange}
                                        label="Security Question 1"
                                    >
                                        <MenuItem value="sq1">SQ 1</MenuItem>
                                        <MenuItem value="sq2">SQ 2</MenuItem>
                                        <MenuItem value="sq3">SQ 3</MenuItem>
                                    </Select>
                                </FormControl>

                                {errors.sq1 && (
                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                        {' '}
                                        {errors.sq1}{' '}
                                    </FormHelperText>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id="answer 1"
                                    name="answer 1"
                                    label="Answer 1"
                                    // value={values.ans1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.ans1 && Boolean(errors.ans1)}
                                    helperText={touched.ans1 && errors.ans1}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="sq2-select">Security Question 2</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="sq2-select"
                                        id="sq2"
                                        name="sq2"
                                        value={values.sq2}
                                        onChange={handleChange}
                                        label="Security Question 2"
                                    >
                                        <MenuItem value="sq1">SQ 1</MenuItem>
                                        <MenuItem value="sq2">SQ 2</MenuItem>
                                        <MenuItem value="sq3">SQ 3</MenuItem>
                                    </Select>
                                </FormControl>
                                {errors.sq2 && (
                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                        {' '}
                                        {errors.sq2}{' '}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id="answer 2"
                                    name="answer 2"
                                    label="Answer 2"
                                    // value={values.ans2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.ans2 && Boolean(errors.ans2)}
                                    helperText={touched.ans2 && errors.ans2}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="sq3-select">Security Question 3</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="sq3-select"
                                        id="sq3"
                                        name="sq3"
                                        value={values.sq3}
                                        onChange={handleChange}
                                        label="Security Question 3"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="sq1">SQ 1</MenuItem>
                                        <MenuItem value="sq2">SQ 2</MenuItem>
                                        <MenuItem value="sq3">SQ 3</MenuItem>
                                    </Select>
                                </FormControl>
                                {errors.sq3 && (
                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                        {' '}
                                        {errors.sq3}{' '}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id="answer 3"
                                    name="answer 3"
                                    label="Answer 3"
                                    // value={values.ans3}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.ans1 && Boolean(errors.ans3)}
                                    helperText={touched.ans1 && errors.ans3}
                                />
                            </Grid>
                            {/* <SecurityQuestionsInnerCardCopy sq="sq1" ans="answer 1" />
                            <SecurityQuestionsInnerCardCopy sq="sq2" ans="answer 2" />
                            <SecurityQuestionsInnerCardCopy sq="sq3" ans="answer 3" /> */}
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    onClick={() => navigate('../proxyonboarding/ProxyUserAgreement')}
                                >
                                    Next
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default ProxySecurityQuestionInnerCard;
