// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "#ffffff",
	"gradient": "transparent linear-gradient(180deg, #0693E1 0%, #1A64F2 100%) 0% 0% no-repeat",
	"headerBg": "transparent linear-gradient(180deg, #E6EAF9 0%, #FEFDFA 50%, #e6eaf9 100%) 0% 0% no-repeat",
	"primaryLight": "#e3f2fd",
	"primary100": "#f6fbff",
	"primary200": "#A6A1A1",
	"primary800": "#1565c0",
	"secondaryLight": "#ede7f6",
	"secondary200": "#b39ddb",
	"secondary800": "#4527a0",
	"successLight": "#b9f6ca",
	"success200": "#69f0ae",
	"successMain": "#00e676",
	"successDark": "#00c853",
	"errorLight": "#ef9a9a",
	"errorMain": "#f44336",
	"errorDark": "#c62828",
	"orangeLight": "#fbe9e7",
	"orangeMain": "#ffab91",
	"orangeDark": "#d84315",
	"warningLight": "#fff8e1",
	"warningMain": "#ffe57f",
	"warningDark": "#ffc107",
	"grey50": "#fafafa",
	"grey100": "#f5f5f5",
	"grey200": "#eeeeee",
	"grey300": "#e0e0e0",
	"grey500": "#9e9e9e",
	"grey600": "#757575",
	"grey700": "#616161",
	"grey900": "#212121",
	"dashboardCardContentBg": "#fbfbfb",
	"dashboardCardHeaderBg": "#ffffff",
	"mainCardContentsBg": "#fafafa",
	"tableHeaderBg": "#ffffff",
	"tableRowBg": "#fafafa",
	"tableExpandBg": "#ffffff",
	"black": "#000000",
	"backgroundGradient": "transparent linear-gradient(180deg, #E6EAF9 0%, #FEFDFA 50%, #EBEFF9 100%) 0% 0% no-repeat",
	"primaryMain": "#1d5ddf",
	"primaryDark": "#0034ac",
	"secondaryMain": "#202263",
	"secondaryDark": "#000038",
	"secondary1": "#57c0f4",
	"secondary2": "#6467f2",
	"secondary3": "#ffa94f",
	"secondary4": "#292c99",
	"secondary5": "#972EF3",
	"secondary6": "#56C065",
	"tertiary1": "#BFC0F6",
	"tertiary2": "#DCDCDC",
	"tertiary3": "#BABABA",
	"tertiary4": "#999999",
	"dietPrimary": "#8dc63f",
	"restPrimary": "#8e7de4",
	"exercisePrimary": "#ff6c0e",
	"attitudePrimary": "#ffd900",
	"medicationPrimary": "#00bed6",
	"specialistPrimary": "#4c87ef",
	"dietSecondary": "#b9dc89",
	"restSecondary": "#9D8CE2",
	"exerciseSecondary": "#FFA46A",
	"attitudeSecondary": "#FFE996",
	"medicationSecondary": "#7EDBE5",
	"specialistSecondary": "#7ca7ec",
	"darkPaper": "#111936",
	"darkBackground": "#1a223f",
	"darkLevel1": "#29314f",
	"darkLevel2": "#212946",
	"darkTextTitle": "#d7dcec",
	"darkTextPrimary": "#bdc8f0",
	"darkTextSecondary": "#8492c4",
	"darkPrimaryLight": "#e3f2fd",
	"darkPrimaryMain": "#2196f3",
	"darkPrimaryDark": "#1e88e5",
	"darkPrimary200": "#90caf9",
	"darkPrimary800": "#1565c0",
	"darkSecondaryLight": "#d1c4e9",
	"darkSecondaryMain": "#7c4dff",
	"darkSecondaryDark": "#651fff",
	"darkSecondary200": "#b39ddb",
	"darkSecondary800": "#6200ea",
	"todaysplanwithtime": "#3A7E92",
	"todaysplanwithouttime": "#4166E4"
};
export default ___CSS_LOADER_EXPORT___;
