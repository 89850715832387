import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import {
    CardContent,
    Divider,
    Drawer,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    Chip,
    useTheme
} from '@mui/material';
// material-ui
// project imports
import useConfig from 'hooks/useConfig';
import PropTypes from 'prop-types';
// third-party
import { FormattedMessage } from 'react-intl';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { appDrawerWidth as drawerWidth, gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import ComposeDialog from './ComposeDialog';

// ==============================|| MAIL DRAWER ||============================== //

const MessageDrawer = ({ filter, handleDrawerOpen, handleFilter, openMailSidebar, unreadCounts }) => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                zIndex: { xs: 1200, xl: 0 },
                '& .MuiDrawer-paper': {
                    height: 'auto',
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    position: 'relative',
                    border: 'none',
                    borderRadius: matchDownSM ? 0 : `${borderRadius}px`,
                    backgroundColor: theme.palette.cardColor.dashboardCardContentBg
                }
            }}
            variant={matchDownSM ? 'temporary' : 'persistent'}
            anchor="left"
            open={openMailSidebar}
            ModalProps={{ keepMounted: true }}
            onClose={handleDrawerOpen}
        >
            {openMailSidebar && (
                <MainCard
                    sx={{
                        bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : theme.palette.primary.light
                    }}
                    border={!matchDownSM}
                    content={false}
                >
                    <CardContent sx={{ height: matchDownSM ? '100vh' : 'auto', backgroundColor: theme.palette.primary.light }}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <ComposeDialog />
                            </Grid>
                            <Grid item xs={12}>
                                <PerfectScrollbar
                                    style={{
                                        height: matchDownSM ? 'calc(100vh - 115px)' : 'calc(100vh - 295px)',
                                        overflowX: 'hidden',
                                        minHeight: matchDownSM ? 0 : 435
                                    }}
                                >
                                    <List
                                        component="nav"
                                        sx={{
                                            '& .MuiListItem-root': {
                                                mb: 0.75,
                                                borderRadius: `${borderRadius}px`,
                                                '& .MuiChip-root': {
                                                    color:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.primary.main
                                                            : theme.palette.secondary.main,
                                                    bgcolor:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.dark.dark
                                                            : theme.palette.secondary.light
                                                }
                                            },
                                            '& .MuiListItem-root.Mui-selected': {
                                                bgcolor:
                                                    theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.secondary.light,
                                                '& .MuiListItemText-primary': {
                                                    color:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.primary.main
                                                            : theme.palette.secondary.main
                                                },
                                                '& .MuiChip-root': {
                                                    color:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.primary.main
                                                            : theme.palette.secondary.light,
                                                    bgcolor:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.dark.main
                                                            : theme.palette.secondary.main
                                                }
                                            }
                                        }}
                                    >
                                        <ListItemButton selected={filter === 'inbox'} onClick={() => handleFilter('inbox')}>
                                            <ListItemIcon>
                                                <InboxTwoToneIcon />
                                            </ListItemIcon>

                                            <ListItemText>
                                                <FormattedMessage id="Inbox" />
                                            </ListItemText>
                                            {unreadCounts?.inbox !== 0 && <Chip label={unreadCounts?.inbox} color="error" size="small" />}
                                        </ListItemButton>
                                        <ListItemButton selected={filter === 'sent'} onClick={() => handleFilter('sent')}>
                                            <ListItemIcon>
                                                <SendTwoToneIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <FormattedMessage id="Sent" />
                                            </ListItemText>
                                            {/* {unreadCounts?.sent !== 0 && <Chip label={unreadCounts?.sent} size="small" />} */}
                                        </ListItemButton>
                                        <ListItemButton selected={filter === 'draft'} onClick={() => handleFilter('draft')}>
                                            <ListItemIcon>
                                                <HistoryEduTwoToneIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <FormattedMessage id="Draft" />
                                            </ListItemText>
                                            {/* {unreadCounts?.draft !== 0 && <Chip label={unreadCounts?.draft} size="small" />} */}
                                        </ListItemButton>
                                        {/* <Divider /> */}
                                    </List>
                                </PerfectScrollbar>
                            </Grid>
                        </Grid>
                    </CardContent>
                </MainCard>
            )}
        </Drawer>
    );
};

MessageDrawer.propTypes = {
    filter: PropTypes.string,
    handleDrawerOpen: PropTypes.func,
    handleFilter: PropTypes.func,
    openMailSidebar: PropTypes.bool,
    unreadCounts: PropTypes.object
};

export default MessageDrawer;
