import { Button, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';

import ActivitySummary from './chart/ActivitySummary';
import DreamsSummaryShort from './DreamsSummaryShort';

const MyActivities = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const dropData = {
        title: 'This Week',
        options: [
            {
                value: 1,
                label: '1 Week'
            },
            {
                value: 104,
                label: '2 Years'
            },
            {
                value: 12,
                label: '3 Months'
            }
        ]
    };
    let dropHtml;
    if (dropData) {
        const handleClick = (event) => {
            setAnchorEl(event?.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        dropHtml = (
            <>
                <Button
                    variant="text"
                    disableElevation
                    size="medium"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    {dropData.title}
                </Button>
                <Menu
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {dropData?.options.map((option, index) => (
                        <MenuItem key={index} onClick={handleClose}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    }
    return (
        // <MainCard
        //     title={
        //         <Grid container>
        //             <Grid item xs={6}>
        //                 My Activities-Calories
        //             </Grid>
        //             <Grid item xs={6} align="right">
        //                 {dropHtml}
        //             </Grid>
        //         </Grid>
        //     }
        //     content={false}
        // >
        //     {/* <Grid container justifyContent="flex-end" alignItems="right" sx={{ pl: 2, pr: 2, pt: 2 }}>
        //         {dropHtml}
        //     </Grid> */}
        //     <CalorieColumnChart />
        // </MainCard>

        // <ActivitySummary title="My Activity Summary" />
        <DreamsSummaryShort />
    );
};

export default MyActivities;
