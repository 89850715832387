// third-party
import { sub } from 'date-fns';
import { Chance } from 'chance';

// project imports
import services from 'utils/mockAdapter';

const chance = new Chance();

// mail conversation
let mails = [
    {
        id: '#2Mail_Phoebe',
        subject: 'You should include the following food contents inorder to maintain a healthy diet',
        isRead: false,
        important: true,
        starred: false,
        time: sub(new Date(), { days: 0, hours: 1, minutes: 45 }),
        topic: 'Diet nutrition guidelines ',
        forums: false,
        attach: false,
        sent: chance.bool(),
        draft: chance.bool(),
        spam: false,
        trash: chance.bool(),
        profile: {
            avatar: 'user-4.png',
            name: 'Robert Hailey',
            email: chance.email({ domain: 'company.com' }),
            to: chance.email({ domain: 'company.com' })
        },
        sender: {
            avatar: 'gloria_hailey_proxy_wife.png',
            name: 'Gloria Hailey',
            email: chance.email({ domain: 'company.com' }),
            to: chance.email({ domain: 'company.com' }),
            about: chance.paragraph({ sentences: 1 }),
            role: 'personal-care-team'
        },
        message:
            "Proper nutrition is vital for good health and we must remind people to consistently eat well. Details of the message: • Eat a minimum of 3 balanced meals per day. Eat 7 servings of fruit and vegetables per day. Eat your fruit don't drink it.",
        attachments: []
    },
    {
        id: '#1Mail_Phoebe',
        subject: 'Instruction for better diet system are being attatched as a diet plan along with the email.',
        isRead: true,
        important: false,
        starred: true,
        time: sub(new Date(), { days: 0, hours: 5, minutes: 45 }),
        topic: 'Tips for better diet',
        forums: true,
        attach: true,
        sent: chance.bool(),
        draft: chance.bool(),
        spam: false,
        trash: chance.bool(),

        profile: {
            avatar: 'user-2.png',
            name: 'Robert Hailey',
            email: chance.email({ domain: 'company.com' }),
            to: chance.email({ domain: 'company.com' })
        },
        sender: {
            avatar: 'dr_frank_ellison_specialist.png',
            name: 'Dr.Frank Ellison',
            email: chance.email({ domain: 'company.com' }),
            to: chance.email({ domain: 'company.com' }),
            about: chance.sentence({ words: 8 }),
            role: 'Specialist'
        },
        message:
            'A balanced diet is a diet that contains differing kinds of foods in certain quantities and proportions so that the requirement for calories, proteins, minerals, vitamins and alternative nutrients is adequate and a small provision is reserved for additional nutrients to endure the short length of leanness. In addition, a balanced diet ought to offer bioactive phytochemicals like dietary fiber, antioxidants and nutraceuticals that have positive health advantages. A balanced diet should offer around 60-70% of total calories from carbohydrates, 10-12% from proteins and 20-25% of total calories from fat.',
        attachments: [
            {
                id: '#1Attach',
                image: 'img-gal-1.png',
                title: 'diet_nutrition.pdf'
            }
        ]
    },
    {
        id: '#3Mail_Phoebe',
        subject: 'This Includes the tips for achieving a better sleep and rest',
        isRead: true,
        important: false,
        starred: false,
        time: sub(new Date(), { days: 1, hours: 1, minutes: 0 }),
        topic: 'Planning sleep and rest',
        forums: false,
        attach: false,
        sent: chance.bool(),
        draft: chance.bool(),
        spam: false,
        trash: chance.bool(),
        profile: {
            avatar: 'user-6.png',
            name: 'Robert Hailey',
            email: chance.email({ domain: 'company.com' }),
            to: chance.email({ domain: 'company.com' })
        },
        sender: {
            avatar: 'issac_white_nurse.png',
            name: 'Issac White',
            email: chance.email({ domain: 'company.com' }),
            to: chance.email({ domain: 'company.com' }),
            about: chance.sentence({ words: 8 }),
            role: 'Clinical Care Team'
        },
        message:
            'Create a regular sleep schedule and a good sleep environment. Other sleep hygiene components include a regular sleep schedule and awakening time, as well as a good sleep environment.Avoid caffeine, alcohol and tobacco. Most people know that evening caffeine can keep you up at night. But what many people don’t realize is how long caffeine stays in your body. ',
        attachments: []
    }
];

// filter functions
const getInboxMails = () => mails.filter((item) => !item.spam);
const getSentMails = () => mails.filter((item) => item.sent);
const getDraftMails = () => mails.filter((item) => item.draft);
const getSpamMails = () => mails.filter((item) => item.spam);
const getTrashMails = () => mails.filter((item) => item.trash);
const getStarredMails = () => mails.filter((item) => item.starred);
const getImportantMails = () => mails.filter((item) => item.important);
const getPromotionsMails = () => mails.filter((item) => item.promotions);
const getForumMails = () => mails.filter((item) => item.forums);

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/mails/list').reply(() => [
    200,
    {
        mails,
        unreadCount: {
            all: mails.filter((i) => !i.isRead).length,
            inbox: getInboxMails().filter((i) => !i.isRead).length,
            sent: getSentMails().filter((i) => !i.isRead).length,
            draft: getDraftMails().filter((i) => !i.isRead).length,
            spam: getSpamMails().filter((i) => !i.isRead).length,
            trash: getTrashMails().filter((i) => !i.isRead).length,
            starred: getStarredMails().filter((i) => !i.isRead).length,
            important: getImportantMails().filter((i) => !i.isRead).length,
            promotions: getPromotionsMails().filter((i) => !i.isRead).length,
            forums: getForumMails().filter((i) => !i.isRead).length
        }
    }
]);

services.onPost('/api/mails/setRead').reply((config) => {
    try {
        const { id } = JSON.parse(config.data);
        const mailIndex = mails.findIndex((i) => i.id === id);
        mails[mailIndex] = { ...mails[mailIndex], isRead: true };
        mails = [...mails];
        return [200, []];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});

services.onPost('/api/mails/setImportant').reply((config) => {
    try {
        const { id } = JSON.parse(config.data);
        const mailIndex = mails.findIndex((i) => i.id === id);
        mails[mailIndex] = { ...mails[mailIndex], important: !mails[mailIndex].important };
        mails = [...mails];
        return [200, []];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});

services.onPost('/api/mails/setStarred').reply((config) => {
    try {
        const { id } = JSON.parse(config.data);
        const mailIndex = mails.findIndex((i) => i.id === id);
        mails[mailIndex] = { ...mails[mailIndex], starred: !mails[mailIndex].starred };
        mails = [...mails];
        return [200, []];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});

services.onPost('/api/mails/filter').reply((config) => {
    try {
        const { filter } = JSON.parse(config.data);

        let result = [];
        switch (filter) {
            case 'inbox':
                result = getInboxMails();
                break;
            case 'sent':
                result = getSentMails();
                break;
            case 'draft':
                result = getDraftMails();
                break;
            case 'spam':
                result = getSpamMails();
                break;
            case 'trash':
                result = getTrashMails();
                break;
            case 'starred':
                result = getStarredMails();
                break;
            case 'important':
                result = getImportantMails();
                break;
            case 'promotions':
                result = getPromotionsMails();
                break;
            case 'forums':
                result = getForumMails();
                break;
            case 'all':
            default:
                result = mails;
                break;
        }

        return [200, result];
    } catch (err) {
        console.error(err);
        return [500, { message: 'Internal server error' }];
    }
});
