import { Card, Grid, Typography, useMediaQuery } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

// =============================|| SIDE ICON CARD ||============================= //

const SideIconCard = ({ iconPrimary, iconPrimaryName, primary, primarySub, secondary, secondarySub, color, bgcolor }) => {
    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));

    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary !== undefined ? <IconPrimary /> : null;

    return (
        <Card sx={{ bgcolor: bgcolor || '', position: 'relative' }}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid
                    item
                    xs={4}
                    sx={{
                        py: 2,
                        px: 0
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: 'center',
                            color: '#ffff',
                            '& > svg': {
                                width: 20,
                                height: 20
                            }
                        }}
                        align="center"
                    >
                        <Grid container direction="column" justifyContent="space-between" spacing={1} alignItems="center">
                            <Grid item sm={2}>
                                {primaryIcon}
                            </Grid>
                            <Grid item sm={2}>
                                {iconPrimaryName}
                            </Grid>
                        </Grid>
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Grid container>
                        <Grid item sm={4}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="space-between"
                                spacing={1}
                                alignItems={matchDownXs ? 'center' : 'flex-start'}
                            >
                                <Grid item sm={2}>
                                    <Typography variant="h5" sx={{ ml: 2 }}>
                                        {primary}
                                    </Typography>
                                </Grid>
                                <Grid item sm={2}>
                                    <Typography variant="body2" align="left" sx={{ ml: 2 }}>
                                        {primarySub}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={4}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="space-between"
                                spacing={1}
                                alignItems={matchDownXs ? 'center' : 'flex-start'}
                            >
                                <Grid item sm={2}>
                                    <Typography variant="h5" sx={{ ml: 2 }}>
                                        {secondary}
                                    </Typography>
                                </Grid>
                                <Grid item sm={2}>
                                    <Typography variant="body2" align="left" sx={{ ml: 2 }}>
                                        {secondarySub}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

SideIconCard.propTypes = {
    iconPrimary: PropTypes.object,
    iconPrimaryName: PropTypes.string,
    primary: PropTypes.string,
    primarySub: PropTypes.string,
    secondary: PropTypes.string,
    secondarySub: PropTypes.string,
    color: PropTypes.string,
    bgcolor: PropTypes.string
};

export default SideIconCard;
