import {
    Button,
    Grid,
    CardActions,
    FormControl,
    IconButton,
    Select,
    MenuItem,
    Typography,
    CardContent,
    Card,
    Modal,
    TextField,
    Autocomplete,
    InputLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useState, useRef } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import MuiTooltip from '@mui/material/Tooltip';
import MainCard from 'ui-component/cards/MainCard';
import CloseIcon from '@mui/icons-material/Close';
import { IconBrandTelegram } from '@tabler/icons';

const providers = [
    { label: 'Sutter Health', id: 1 },
    { label: 'ABC Health', id: 2 },
    { label: 'Star Health', id: 3 }
];

const ProxyConnectProviderCard = () => {
    const [provider, setProvider] = useState(providers[0]);
    const rootRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [openinvite, setOpenInvite] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const handleInvite = () => {
        setOpen(false);
        setOpenInvite(true);
    };
    const handleCloseInvite = () => setOpenInvite(false);
    const handleChange = (e) => {
        setProvider(e.target.value);
    };

    const theme = useTheme();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        // border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    const cardHeaderStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.dashboardCardHeaderBg,
        // border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    return (
        <Grid xs={12} lg={10}>
            <MainCard sx={cardHeaderStyle} content={false}>
                <CardContent sx={cardStyle}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                <b> Download Health Records from Robert Hailey&apos;s provider </b>
                                <MuiTooltip title="Download health Records" aria-label="download">
                                    <IconButton aria-label="info" size="small">
                                        <InfoIcon color="primary" fontSize="small" />{' '}
                                    </IconButton>
                                </MuiTooltip>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <Autocomplete
                                disableClearable
                                options={providers}
                                defaultValue={provider}
                                onChange={handleChange}
                                size="small"
                                renderInput={(params) => <TextField {...params} label="" />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button variant="contained"> Connect</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </MainCard>
        </Grid>
    );
};
export default ProxyConnectProviderCard;
