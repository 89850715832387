/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    Chip,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useIntl } from 'react-intl';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useFormik } from 'formik';
import { useState } from 'react';
import { PhotoCamera } from '@mui/icons-material';
import styled from '@emotion/styled';

// =============================|| REVENUE CARD ||============================= //

const RateSleepQuality = ({ title, subtitle, schedule, iconPrimary, color, handleDrawerClose }) => {
    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));

    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary ? <IconPrimary fontSize="large" /> : null;
    const intl = useIntl();

    const cardStyle = {
        pt: 1,
        pl: 1,
        pr: 1,
        mt: 1,
        pb: '0px !important',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid #e9e8e8'
    };
    const [value, setValue] = useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    return (
        <>
            <Card sx={{ background: theme.palette.secondary.main, position: 'relative', color: '#fff' }}>
                <CardContent>
                    <Typography
                        variant="body2"
                        sx={{
                            position: 'absolute',
                            right: 13,
                            top: 3,
                            color: '#fff',
                            '&> svg': { width: 100, height: 70, opacity: '0.5' },
                            [theme.breakpoints.down('sm')]: {
                                top: 13,
                                '&> svg': { width: 80, height: 80 }
                            }
                        }}
                    >
                        {primaryIcon}
                    </Typography>
                    <Grid container direction={matchDownXs ? 'column' : 'row'} spacing={1}>
                        <Grid item xs={12} />
                        <Grid item xs={12}>
                            <Typography variant="h5" color="inherit">
                                Rate Your Sleep Quality
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={cardStyle}>
                <CardContent sx={{ pt: 0, pr: 0, pb: '8px !important' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <RadioGroup
                                onClick={handleChange}
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={value}
                            >
                                <FormControlLabel value="verygood" control={<Radio />} label="Very Good" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={cardStyle}>
                <CardContent sx={{ pt: 0, pr: 0, pb: '8px !important' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <RadioGroup
                                onClick={handleChange}
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={value}
                            >
                                <FormControlLabel value="good" control={<Radio />} label="Good" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={cardStyle}>
                <CardContent sx={{ pt: 0, pr: 0, pb: '8px !important' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <RadioGroup
                                onClick={handleChange}
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={value}
                            >
                                <FormControlLabel value="fair" control={<Radio />} label="Fair" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={cardStyle}>
                <CardContent sx={{ pt: 0, pr: 0, pb: '8px !important' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <RadioGroup
                                onClick={handleChange}
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={value}
                            >
                                <FormControlLabel value="poor" control={<Radio />} label="Poor" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {value && (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item>
                        <Button onClick={handleDrawerClose} variant="contained">
                            Save
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleDrawerClose} variant="outlined">
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

RateSleepQuality.propTypes = {
    schedule: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    iconPrimary: PropTypes.object,
    color: PropTypes.string,
    handleDrawerClose: PropTypes.func
};

export default RateSleepQuality;
