import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Typography,
    Grid,
    styled,
    Button,
    tableCellClasses,
    Modal
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

import DeleteIcon from '@mui/icons-material/Delete';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
// assets
import { gridSpacing } from 'store/constant';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState, useRef } from 'react';
import CardMedia from '@mui/material/CardMedia';

// table data
function createData(name, type, category, date, source, expand) {
    return {
        name,
        type,
        category,
        date,
        source,
        expand
    };
}

function Row({ row }) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [openmodal, setOpenModal] = useState(false);
    const rootRef = useRef(null);

    const cardStyle = {
        pt: '15px',
        pl: '15px',
        pb: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.tableColor.tableExpandBg
        // borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.root}`]: {
            backgroundColor: theme.palette.tableColor.tableRowBg,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));
    const handleClose = () => {
        setOpen(!open);
    };

    const handleCloseModal = () => {
        setOpenModal(!openmodal);
    };
    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell component="th" scope="row">
                    <b>{row.name}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.type}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.category}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.date}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <b>{row.source}</b>
                </StyledTableCell>
                <StyledTableCell>
                    <Stack direction="row" spacing={3}>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            <ExpandCircleDownOutlinedIcon color="primary" />
                        </IconButton>
                        <IconButton aria-label="expand row" size="small">
                            <VisibilityIcon color="primary" onClick={() => setOpenModal(!openmodal)} />
                        </IconButton>
                        <IconButton aria-label="expand row" size="small">
                            <DeleteIcon color="error" />
                        </IconButton>
                    </Stack>
                </StyledTableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <TableContainer>
                                <Table aria-label="purchases">
                                    <TableBody>
                                        {row.expand?.map((items) => (
                                            <TableRow>
                                                <Grid container spacing={gridSpacing} sx={cardStyle}>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={gridSpacing}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h5"> Document Name</Typography>
                                                                Diet Guidelines
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h5">Date</Typography>
                                                                05/06/2021
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={gridSpacing}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h5">Document Type</Typography>
                                                                Document
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Typography variant="h5">Source</Typography>
                                                                Gloria Hailey
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={gridSpacing}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h5">Document Category </Typography>
                                                                Diet
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h5">Note</Typography>
                                                        Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur,
                                                        adipisci velit
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button variant="outlined" onClick={handleClose}>
                                                            Close
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Collapse>
                    {openmodal && (
                        // <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 150px)', overflowX: 'hidden' }}>
                        <div>
                            <Modal
                                disablePortal
                                disableEnforceFocus
                                disableAutoFocus
                                open
                                align="center"
                                aria-labelledby="proxyconnection-modal-title"
                                aria-describedby="proxy-modal-description"
                                disableScrollLock
                                sx={{
                                    p: 1,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'scroll'
                                }}
                                container={() => rootRef.current}
                            >
                                <MainCard
                                    sx={{
                                        width: 1100,
                                        zIndex: 1
                                    }}
                                    title={
                                        <>
                                            <center>Diet Guidelines received on 05/06/2022</center>
                                        </>
                                    }
                                    content={false}
                                    align="left"
                                    secondary={
                                        <IconButton size="large">
                                            <CloseIcon fontSize="small" onClick={handleCloseModal} />
                                        </IconButton>
                                    }
                                >
                                    <div>
                                        <iframe
                                            src="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                                            frameBorder="0"
                                            scrolling="auto"
                                            height="700px"
                                            width="100%"
                                            title="record"
                                        />
                                    </div>
                                    {/* <CardMedia   className={classes.media} component="iframe" src="./dummy1.pdf" scrolling="auto" height="50%" width="50%" /> */}
                                </MainCard>
                            </Modal>
                        </div>
                        // </PerfectScrollbar>
                    )}
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.object
};

const rows = [createData('Diet Guidelines', 'Document', 'Diet', '05/06/2022', 'Gloria Hailey', ['Lorem Ipsum'])];

// RECCOMMEND TESTING BEHAVIOR IN APP TO UNDERSTAND PURPOSE OF THE CODE

export default function DocumentsTable() {
    const theme = useTheme();

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.tableColor.tableHeaderBg,
            color: theme.palette.black.black
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    return (
        <MainCard content={false}>
            {/* table */}
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Category</StyledTableCell>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell>Source</StyledTableCell>
                            <StyledTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <Row key={index} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
}
