/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    Chip,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useIntl } from 'react-intl';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useFormik } from 'formik';
import { useState } from 'react';
import { PhotoCamera } from '@mui/icons-material';
import styled from '@emotion/styled';

// =============================|| REVENUE CARD ||============================= //

const TodayPlanDrawerCard = ({ title, subtitle, schedule, iconPrimary, color, handleDrawerClose }) => {
    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));

    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary ? <IconPrimary fontSize="large" /> : null;
    const intl = useIntl();

    // autocomplete options
    const autocompleteList1 = [
        { label: 'Yogurt (59 Cals)', id: 1 },
        { label: 'Oats (95 Cals)', id: 2 },
        { label: 'Berries (57 Cals)', id: 3 }
    ];
    const autocompleteList2 = [
        { label: '1', id: 1 },
        { label: '2', id: 2 },
        { label: '3', id: 3 }
    ];

    const autocompleteList3 = [
        { label: 'Oz', id: 1 },
        { label: 'Medium Serving', id: 2 },
        { label: 'Large Serving', id: 3 }
    ];
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            attachments: ''
        },

        onSubmit: (values) => {
            const itemToEdit = {
                attachments: values.attachments
            };
        }
    });
    const cardStyle = {
        pt: 1,
        pl: 1,
        pr: 1,
        mt: 1,
        pb: '0px !important',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid #e9e8e8'
    };
    const [value, setValue] = useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const Input = styled('input')({
        display: 'none'
    });
    return (
        <>
            <Card sx={{ background: color, position: 'relative', color: '#fff' }}>
                <CardContent>
                    <Typography
                        variant="body2"
                        sx={{
                            position: 'absolute',
                            right: 13,
                            top: 14,
                            color: '#fff',
                            '&> svg': { width: 100, height: 100, opacity: '0.5' },
                            [theme.breakpoints.down('sm')]: {
                                top: 13,
                                '&> svg': { width: 80, height: 80 }
                            }
                        }}
                    >
                        {primaryIcon}
                    </Typography>
                    <Grid container direction={matchDownXs ? 'column' : 'row'} spacing={1}>
                        <Grid item xs={12}>
                            {schedule !== '' ? (
                                <Chip
                                    label={schedule}
                                    size="small"
                                    sx={{
                                        color: theme.palette.secondary.main,
                                        backgroundColor: theme.palette.primary.paper
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            {/* <Typography variant="h5" color="inherit" sx={{ mt: 1 }}>
                            {title}
                        </Typography> */}
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="h5" color="inherit">
                                Your breakfast plan for today was {subtitle}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={cardStyle}>
                <CardContent sx={{ pt: 0, pr: 0, pb: '8px !important' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio />}
                                    label={intl.formatMessage({ id: 'today_plan_breakfast_yes' })}
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={cardStyle}>
                <CardContent sx={{ pt: 0, pr: 0, pb: '8px !important' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value="no"
                                    control={<Radio />}
                                    label={intl.formatMessage({ id: 'today_plan_breakfast_no' })}
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={cardStyle}>
                <CardContent sx={{ pt: 0, pr: 0, pb: '8px !important' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="nothing" control={<Radio />} label="No, I didn't have anything" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {value === 'no' && (
                <>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Typography variant="h4" sx={{ mt: 3, mb: 1 }}>
                                Enter what you had for breakfast?
                            </Typography>
                        </Grid>
                    </Grid>
                    <Card sx={cardStyle}>
                        <CardContent sx={{ p: 1 }}>
                            <FormGroup>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={autocompleteList1}
                                            defaultValue={autocompleteList1[1]}
                                            renderInput={(params) => <TextField {...params} label="Choose what you had for breakfast" />}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            disableClearable
                                            options={autocompleteList2}
                                            renderInput={(params) => <TextField {...params} label="Quantity" />}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Autocomplete
                                            disableClearable
                                            options={autocompleteList3}
                                            renderInput={(params) => <TextField {...params} label="Servings" />}
                                        />
                                    </Grid>
                                    <Grid item xs={3} align="right">
                                        <IconButton sx={{ color: theme.palette.primary.main }}>
                                            <AddCircleIcon sx={{ fontSize: '35px' }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </FormGroup>
                        </CardContent>
                    </Card>
                    <Typography variant="h5" align="center" sx={{ m: 1 }}>
                        OR
                    </Typography>
                    <Card sx={cardStyle}>
                        <CardContent sx={{ pt: 1, pr: 0, pb: '16px !important' }}>
                            <Stack direction="row" justifyContent="center" spacing={2}>
                                <label htmlFor="icon-button-file">
                                    <Input accept="image/*" id="icon-button-file" type="file" />
                                    <Button variant="outlined" size="medium" align="center" startIcon={<PhotoCamera />}>
                                        Take a Photo of what I had
                                    </Button>
                                    {/* <IconButton color="primary" aria-label="Take a Photo of what I had" component="span">
                                                <PhotoCamera /> <Typography> Take a Photo of what I had </Typography>
                                            </IconButton> */}
                                </label>
                            </Stack>
                        </CardContent>
                    </Card>

                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item>
                            <Button variant="contained" onClick={handleDrawerClose}>
                                Save
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={handleDrawerClose}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
            {value === 'yes' && (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item>
                        <Button variant="contained">Save</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined">Cancel</Button>
                    </Grid>
                </Grid>
            )}
            {value === 'nothing' && (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item>
                        <Button variant="contained">Save</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined">Cancel</Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

TodayPlanDrawerCard.propTypes = {
    schedule: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    iconPrimary: PropTypes.object,
    color: PropTypes.string,
    handleDrawerClose: PropTypes.func
};

export default TodayPlanDrawerCard;
