// material-ui
import { Table, TableBody, Grid, TableContainer, TableHead, TableRow, Card, useTheme } from '@mui/material';
import MuiTypography from '@mui/material/Typography';
// project imports
import SubCard from 'ui-component/cards/SubCard';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.common.white
    }
    /*  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    }, */
}));

// table data
function createData(shelement, shcode, desc, dates) {
    return { shelement, shcode, desc, dates };
}

const rows = [
    createData('Tobacco smoking status', '851006', 'Former Smoker', '- 12/12/2000'),
    createData('Sex assigned at birth', 'M', 'Male', '-')
];

// ==============================|| TABLE - DENSE ||============================== //

export default function SocialHistoryTable() {
    const theme = useTheme();
    /*   const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#e9e8e8',
        minWidth: 600
    }; */
    return (
        <SubCard>
            <Grid item>
                <MuiTypography variant="h4" color={theme.palette.text.dark} gutterBottom>
                    Social History
                </MuiTypography>
            </Grid>
            <TableContainer sx={{ border: '1px solid', borderColor: 'Black' }}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">Social History Element</StyledTableCell>
                            <StyledTableCell align="left">SNOMED Code</StyledTableCell>
                            <StyledTableCell align="left">Description</StyledTableCell>
                            <StyledTableCell align="left">Effective Dates</StyledTableCell>
                            {/* <TableCell sx={{ pr: 3 }} align="right">
                                Protein&nbsp;(g)
                            </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow hover key={row.name}>
                                <TableCell align="left">{row.shelement}</TableCell>
                                <TableCell align="left">{row.shcode}</TableCell>
                                <TableCell align="left">{row.desc}</TableCell>
                                <TableCell align="left">{row.dates}</TableCell>
                                {/* <TableCell sx={{ pr: 3 }} align="right">
                                    {row.protein}
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </SubCard>
    );
}
