import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Grid, ToggleButton, ToggleButtonGroup, Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ItemsCarousel from 'react-items-carousel';
import MainCard from '../../../ui-component/cards/MainCard';
import CareTeamProfileCard from './CareTeamProfileCard';

const MyCareTeam = () => {
    const theme = useTheme();
    const [active, setActive] = useState(0);
    const [view, setView] = useState('clinical');
    const [alignment, setAlignment] = useState('clinical');

    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const ClinicalClick = () => {
        if (view !== 'clinical') {
            setView('clinical');
        }
    };

    const PersonalClick = () => {
        if (view !== 'personal') {
            setView('personal');
        }
    };
    const specialists = [
        {
            id: '#1Card_Kelli',
            avatar: 'dr_roger_miles_primary_physician.png',
            name: 'Dr.Roger Miles',
            role: 'Primary Physician',
            status: 'Full Access',
            provider: 'Sutter Health',
            email: 'example@gmail.com',
            mobile: '+1 (141) 521 - 2254'
        },
        {
            id: '#2Card_Laurence',
            avatar: 'susan_richards_care_navigator.png',
            name: 'Susan Richards',
            role: 'Care Navigator',
            status: 'Full Access',
            provider: 'Sutter Health',
            email: 'example@gmail.com',
            mobile: '+1 (141) 521 - 2254'
        },
        {
            id: '#3Card_Melyssa',
            avatar: 'issac_white_nurse.png',
            name: 'Isaac White',
            role: 'Nurse',
            status: 'Full Access',
            provider: 'Sutter Health',
            email: 'example@gmail.com',
            mobile: '+1 (141) 521 - 2254'
        },
        {
            id: '#4Card_Montana',
            avatar: 'dr_frank_ellison_specialist.png',
            name: 'Dr.Frank Ellison',
            role: 'Specialist -Endo',
            status: 'Limited Access',
            provider: 'Mercy Health',
            email: 'example@gmail.com',
            mobile: '+1 (141) 521 - 2254'
        }
    ];

    const personalMembers = [
        {
            id: '#6Card_Wilfrid',
            avatar: 'gloria_hailey_proxy_wife.png',
            profile: 'profile-back-6.png',
            name: 'Gloria Hailey',
            role: 'Proxy',
            status: 'Full Access',
            relation: 'Wife',
            email: 'example@gmail.com',
            mobile: '+1 (141) 521 - 2254'
        },
        // {
        //     id: '#7Card_Kennedy',
        //     avatar: 'casey_hailey_son.png',
        //     profile: 'profile-back-7.png',
        //     name: 'Casey Hailey',
        //     role: 'Family Care Team',
        //     status: 'Invited',
        //     relation: 'Son',
        //     email: 'example@gmail.com',
        //     mobile: '+1 (141) 521 - 2254'
        // },
        {
            id: '#8Card_Ida',
            avatar: 'alexander_hailey_son.png',
            profile: 'profile-back-8.png',
            name: 'Alexander Hailey',
            role: 'Family Care Team',
            status: 'Limited Access',
            relation: 'Son',
            email: 'example@gmail.com',
            mobile: '+1 (141) 521 - 2254'
        },
        {
            id: '#9Card_Madyson',
            avatar: 'sara_hailey_daughter.png',
            profile: 'profile-back-9.png',
            name: 'Sara Hailey',
            role: 'Family Care Team',
            status: 'Full Access',
            relation: 'Daughter',
            email: 'example@gmail.com',
            mobile: '+1 (141) 521 - 2254'
        }
    ];

    return (
        <>
            <MainCard
                title={
                    <>
                        <Grid container alignItems="center">
                            <Grid item xs={6}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="h3">My Care Team</Typography>
                                    <Button size="small" variant="contained">
                                        Invite a Specialist
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} align="right">
                                <ToggleButtonGroup
                                    aria-label="text alignment"
                                    sx={{
                                        outlineColor: theme.palette.primary.main,
                                        outlineWidth: '1px',
                                        outlineStyle: 'solid',
                                        margin: '2px'
                                    }}
                                    color="primary"
                                    size="small"
                                    value={alignment}
                                    exclusive
                                    onChange={handleChange}
                                >
                                    <ToggleButton value="clinical" aria-label="left aligned" onClick={ClinicalClick}>
                                        <FormattedMessage id="Clinical" />
                                    </ToggleButton>
                                    <ToggleButton value="personal" aria-label="right aligned" onClick={PersonalClick}>
                                        <FormattedMessage id="Personal" />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </>
                }
            >
                {/* <ItemsCarousel
                    infiniteLoop="false"
                    gutter={8}
                    activePosition="center"
                    chevronWidth={22}
                    disableSwipe={false}
                    alwaysShowChevrons={false}
                    numberOfCards={4}
                    slidesToScroll={1}
                    outsideChevron="true"
                    // showSlither="false"
                    firstAndLastGutter={false}
                    activeItemIndex={active}
                    requestToChangeActive={(value) => setaAtive(value)}
                    rightChevron={<ArrowForwardIosIcon sx={{ fontSize: '30px' }} />}
                    leftChevron={<ArrowBackIosNewIcon sx={{ fontSize: '30px' }} />}
                > */}
                {view === 'clinical' && (
                    <ItemsCarousel
                        infiniteLoop="false"
                        gutter={8}
                        activePosition="center"
                        chevronWidth={22}
                        disableSwipe={false}
                        alwaysShowChevrons={false}
                        numberOfCards={4}
                        slidesToScroll={1}
                        outsideChevron="true"
                        // showSlither="false"
                        firstAndLastGutter={false}
                        activeItemIndex={active}
                        requestToChangeActive={(value) => setActive(value)}
                        rightChevron={<ArrowForwardIosIcon sx={{ fontSize: '30px' }} />}
                        leftChevron={<ArrowBackIosNewIcon sx={{ fontSize: '30px' }} />}
                    >
                        {specialists.map((item, index) => (
                            <CareTeamProfileCard {...item} />

                            // {/* <CareTeamCard {...specialists[0]} /> */}
                        ))}
                    </ItemsCarousel>
                )}
                {view === 'personal' && (
                    <>
                        <ItemsCarousel
                            infiniteLoop="false"
                            gutter={8}
                            activePosition="center"
                            chevronWidth={22}
                            disableSwipe={false}
                            alwaysShowChevrons={false}
                            numberOfCards={4}
                            slidesToScroll={1}
                            outsideChevron="true"
                            // showSlither="false"
                            firstAndLastGutter={false}
                            activeItemIndex={active}
                            requestToChangeActive={(value) => setActive(value)}
                            rightChevron={<ArrowForwardIosIcon sx={{ fontSize: '30px' }} />}
                            leftChevron={<ArrowBackIosNewIcon sx={{ fontSize: '30px' }} />}
                        >
                            {personalMembers.map((item, index) => (
                                <CareTeamProfileCard {...item} />

                                // {/* <CareTeamCard {...specialists[0]} /> */}
                            ))}
                        </ItemsCarousel>
                    </>
                )}
                {/* {specialists.map((item, index) => (
                        <CareTeamProfileCard {...item} /> */}
                {/* <CareTeamCard {...specialists[0]} /> */}
                {/* ))} */}
            </MainCard>
        </>
    );
};

export default MyCareTeam;

/* <MainCard title="My Care Team">
            <Grid container xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={3} sm={3} md={4} lg={3}>
                    <CareTeamProfileCard {...specialists[0]} />
                </Grid>
                <Grid item xs={3} sm={3} md={4} lg={3}>
                    <CareTeamProfileCard {...specialists[1]} />
                </Grid>
                <Grid item xs={3} sm={3} md={4} lg={3}>
                    <CareTeamProfileCard {...specialists[2]} />
                </Grid>
                <Grid item xs={3} sm={3} md={4} lg={3}>
                    <CareTeamProfileCard {...specialists[3]} />
                </Grid>
            </Grid>
        </MainCard> */
