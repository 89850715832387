import { lazy } from 'react';
// project imports
import ProxyLayout from 'layout/ProxyLayout';
import ProxyAccountSettings from 'views/pct/accountsettings';
import ProxyHowItWorks from 'views/pct/howitworks';
import ProxyMessages from 'views/pct/messages';
import GuestGuard from 'utils/route-guard/GuestGuard';
import Loadable from 'ui-component/Loadable';
import ProxyConnectProviderCard from 'views/pct/downloadhealthrecords/ProxyConnectProviderCard';

const ProxyDashboard = Loadable(lazy(() => import('views/pct/dashboard')));
const ProxyTasks = Loadable(lazy(() => import('views/pct/tasks')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: (
        <GuestGuard>
            <ProxyLayout />
        </GuestGuard>
    ),
    children: [
        {
            path: '/proxydashboard',
            element: <ProxyDashboard />
        },
        {
            path: '/proxytasks',
            element: <ProxyTasks />
        },
        {
            path: '/proxymessages',
            element: <ProxyMessages />
        },
        {
            path: '/proxyaccountsettings',
            element: <ProxyAccountSettings />
        },
        {
            path: '/proxyhowitworks',
            element: <ProxyHowItWorks />
        },
        {
            path: '/proxydownloadhealthrecords',
            element: <ProxyConnectProviderCard />
        }
    ]
};

export default AuthenticationRoutes;
