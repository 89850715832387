import AccessAlarmsTwoToneIcon from '@mui/icons-material/AccessAlarmsTwoTone';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import HourglassTopTwoToneIcon from '@mui/icons-material/HourglassTopTwoTone';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Avatar, Card, CardContent, Chip, Grid, IconButton, Typography, Button, CardActions } from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useNavigate } from 'react-router-dom';
import { activeItem } from 'store/slices/menu';
import { useDispatch } from 'store';

const TodaysPlanCard = ({ plans, handleDrawerOpen, isPickedDateisCurrentDate, callfrom }) => {
    const theme = useTheme();
    const [showMore, setShowMore] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClick = () => {
        navigate('/tasks');
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'tasks');
        if (currentIndex > -1) {
            dispatch(activeItem(['tasks']));
        }
    };

    const renderRows = (plans, condensedCount) => {
        let obj = <></>;
        obj = (
            <>
                {plans.map((plan, index) =>
                    index + 1 <= condensedCount ? (
                        <>
                            <Grid key={index} item xs={12} sx={{ pl: '16px !important', pt: '16px !important' }}>
                                <Grid container spacing={2}>
                                    <Grid item sx={{ pt: '16px !important' }}>
                                        <Avatar
                                            sx={{
                                                color: 'white',
                                                backgroundColor: plan.bgColor
                                                // plan.schedule === ''
                                                //     ? theme.palette.dreams.rest.primary
                                                //     : theme.palette.dreams.rest.primary
                                            }}
                                            size="sm"
                                        >
                                            {plan.schedule === '' ? <HourglassTopTwoToneIcon /> : <AccessAlarmsTwoToneIcon />}
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs zeroMinWidth sx={{ pt: '16px !important' }}>
                                        <Card sx={{ p: 0 }}>
                                            <CardContent
                                                sx={{
                                                    pt: 0,
                                                    pb: '5px !important',
                                                    pl: '16px !important',
                                                    pr: 0,
                                                    backgroundColor: theme.palette.primary.light
                                                }}
                                            >
                                                <Grid container spacing={0} alignItems="center">
                                                    <Grid item xs={10} lg={10} md={10}>
                                                        <Typography align="left" variant="dashboardCardContent" color="secondary">
                                                            {plan.schedule !== undefined ? (
                                                                <Chip
                                                                    label={plan.schedule}
                                                                    size="small"
                                                                    sx={{
                                                                        color: theme.palette.primary.light,
                                                                        backgroundColor: theme.palette.iconColor.todaysplanwithouttime
                                                                    }}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}{' '}
                                                            {plan.pending !== undefined ? (
                                                                <>
                                                                    <Chip
                                                                        label={plan.pending}
                                                                        size="small"
                                                                        sx={{
                                                                            mb: 1,
                                                                            color: theme.palette.primary.light,
                                                                            backgroundColor: theme.palette.iconColor.todaysplanwithouttime
                                                                        }}
                                                                    />
                                                                    <br />
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {plan.title}
                                                        </Typography>
                                                        {plan.subTitile && (
                                                            <Typography
                                                                align="left"
                                                                variant="body1"
                                                                sx={{ pt: 1, color: theme.palette.grey[700] }}
                                                            >
                                                                {plan.subTitile}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={2} lg={2} md={2} align="center">
                                                        <IconButton size="small" onClick={() => handleDrawerOpen(plan)}>
                                                            {!plan.completed
                                                                ? !plan.isCalendarDrawer && (
                                                                      <ArrowRightIcon color="primary" fontSize="large" />
                                                                  )
                                                                : isPickedDateisCurrentDate && <CheckCircleIcon color="success" />}
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )
                )}
                {showMore &&
                    plans.map((plan, index) =>
                        index + 1 > condensedCount ? (
                            <>
                                <Grid key={index} item xs={12} sx={{ pl: '16px !important', pt: '16px !important' }}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Avatar
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor: plan.bgColor
                                                }}
                                                size="sm"
                                            >
                                                {plan.schedule === '' ? <HourglassTopTwoToneIcon /> : <AccessAlarmsTwoToneIcon />}
                                            </Avatar>
                                        </Grid>
                                        <Grid item xs zeroMinWidth>
                                            <Card sx={{ p: 0 }}>
                                                <CardContent
                                                    sx={{
                                                        pt: 0,
                                                        pb: '5px !important',
                                                        pl: '16px !important',
                                                        pr: 0,
                                                        backgroundColor: theme.palette.primary.light
                                                    }}
                                                >
                                                    <Grid container spacing={0} alignItems="center">
                                                        <Grid item xs={10} lg={10} md={10}>
                                                            <Typography align="left" variant="dashboardCardContent" color="secondary">
                                                                {plan.schedule !== undefined ? (
                                                                    <Chip
                                                                        label={plan.schedule}
                                                                        size="small"
                                                                        sx={{
                                                                            color: theme.palette.primary.light,
                                                                            backgroundColor: theme.palette.iconColor.todaysplanwithouttime
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    ''
                                                                )}{' '}
                                                                {plan.pending !== undefined ? (
                                                                    <>
                                                                        <Chip
                                                                            label={plan.pending}
                                                                            size="small"
                                                                            sx={{
                                                                                mb: 1,
                                                                                color: theme.palette.primary.light,
                                                                                backgroundColor:
                                                                                    theme.palette.iconColor.todaysplanwithouttime
                                                                            }}
                                                                        />
                                                                        <br />
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {plan.title}
                                                            </Typography>
                                                            {plan.subTitile && (
                                                                <Typography
                                                                    align="left"
                                                                    variant="body1"
                                                                    sx={{ pt: 1, color: theme.palette.grey[700] }}
                                                                >
                                                                    {plan.subTitile}
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={2} lg={2} md={2} align="center">
                                                            <IconButton size="small" onClick={() => handleDrawerOpen(plan)}>
                                                                {!plan.completed
                                                                    ? !plan.isCalendarDrawer && (
                                                                          <ArrowRightIcon color="primary" fontSize="large" />
                                                                      )
                                                                    : isPickedDateisCurrentDate && <CheckCircleIcon color="success" />}
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <></>
                        )
                    )}

                {callfrom === 'Calendar' && (
                    <>
                        {plans.map((plan, index) => (
                            <>
                                <Grid key={index} item xs={12} sx={{ pl: '16px !important', pt: '16px !important' }}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Avatar
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor: plan.bgColor
                                                }}
                                                size="sm"
                                            >
                                                {plan.schedule === '' ? <HourglassTopTwoToneIcon /> : <AccessAlarmsTwoToneIcon />}
                                            </Avatar>
                                        </Grid>
                                        <Grid item xs zeroMinWidth>
                                            <Card sx={{ p: 0 }}>
                                                <CardContent
                                                    sx={{
                                                        pt: 0,
                                                        pb: '5px !important',
                                                        pl: '16px !important',
                                                        pr: 0,
                                                        backgroundColor: theme.palette.primary.light
                                                    }}
                                                >
                                                    <Grid container spacing={0} alignItems="center">
                                                        <Grid item xs={9} lg={9} md={9}>
                                                            <Typography align="left" color="secondary" variant="dashboardCardContent">
                                                                {plan.schedule !== '' ? (
                                                                    <Chip
                                                                        label={plan.schedule}
                                                                        size="small"
                                                                        sx={{
                                                                            color: theme.palette.primary.light,
                                                                            backgroundColor: theme.palette.iconColor.todaysplanwithouttime
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    ''
                                                                )}{' '}
                                                                {plan.title}
                                                            </Typography>
                                                            {plan.subTitile && (
                                                                <Typography
                                                                    align="left"
                                                                    variant="body1"
                                                                    sx={{ pt: 1, color: theme.palette.grey[700] }}
                                                                >
                                                                    {plan.subTitile}
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={3} lg={3} md={3} align="right">
                                                            {!plan.completed
                                                                ? !plan.isCalendarDrawer && (
                                                                      <ArrowRightIcon color="primary" fontSize="large" />
                                                                  )
                                                                : isPickedDateisCurrentDate && <CheckCircleIcon color="success" />}
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </>
                )}
            </>
        );

        return obj;
    };

    return (
        <>
            <CardContent sx={{ backgroundColor: theme.palette.primary.light, pr: 0.5 }}>
                <Grid
                    container
                    spacing={gridSpacing}
                    alignItems="center"
                    sx={{
                        position: 'relative',
                        '&>*': {
                            position: 'relative',
                            zIndex: '5'
                        },
                        '&:after': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 35,
                            width: 2,
                            height: '100%',
                            background: '#ebebeb',
                            zIndex: '1'
                        }
                    }}
                >
                    {callfrom !== 'Calendar' ? <> {renderRows(plans, 5)}</> : <> {renderRows(plans, 0)}</>}
                </Grid>
            </CardContent>
            {callfrom !== 'Calendar' && (
                <>
                    <CardActions sx={{ p: 1, justifyContent: 'flex-end' }}>
                        {!showMore && plans.length > 5 && (
                            <Button size="small" variant="text" onClick={() => setShowMore((prev) => !prev)}>
                                <b>Show More</b>
                            </Button>
                        )}
                        {showMore && (
                            <Button size="small" variant="text" onClick={() => setShowMore((prev) => !prev)}>
                                <b>Show Less</b>
                            </Button>
                        )}
                        <Button size="small" variant="text" onClick={handleClick}>
                            <b>View All</b>
                        </Button>
                    </CardActions>
                </>
            )}
        </>
    );
};

export default TodaysPlanCard;

TodaysPlanCard.propTypes = {
    handleDrawerOpen: PropTypes.func,
    plans: PropTypes.array,
    isPickedDateisCurrentDate: PropTypes.bool
};
