// material-ui
import { useTheme } from '@emotion/react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { gridSpacing } from 'store/constant';
// import welcomeIcon from '../welcomecard-icon.svg';
import { ReactComponent as welcomeIcon } from '../../assets/images/dashboard/welcomecard-icon.svg';
import DashboardTabs from './dashboardtabs';
import MyActivities from './myactivities';
import MyCalendar from './mycalendar';
import MyCareTeam from './mycareteam';
import MyMessages from './mymessages';
import MySummary from './mysummary';
import TodaysPlan from './todayplan';
import Todo from './todo';
import WelcomeCard from './WelcomeCard';
import CareTeamTab from '../dreams/CareTeamTab';

const Dashboard = () => {
    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} lg={8} md={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        {/* <Typography variant="h1" color="secondary">
                            Welcome, Robert Haiely!
                        </Typography> */}
                        {/* <WelcomeCard
                            primary="Welcome, Robert Hailey!"
                            content="Let us get and organise your health data, care plans to setup your DREAMS activity"
                            color={theme.palette.secondary.main}
                            iconPrimary={welcomeIcon}
                            opacity="0.6"
                            iwidth={70}
                            iheight={65}
                        /> */}
                        <Grid container direction={matchDownXs ? 'column' : 'row'} spacing={1}>
                            <Grid item xs={12}>
                                <Typography color="secondary" variant="h1">
                                    Welcome, Robert Hailey!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="secondary" variant="h3">
                                    Let us get and organise your health data, care plans to setup your DREAMS activity
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="h4" color="secondary">
                            Let us get and organise your health data, care plans to setup your DREAMS activity
                        </Typography>
                    </Grid> */}

                    <Grid item xs={12}>
                        <MyActivities />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <DashboardTabs /> */}
                        <MySummary />
                    </Grid>
                    <Grid item xs={12}>
                        <MyMessages />
                    </Grid>
                    <Grid item xs={12}>
                        <CareTeamTab callfrom="dashboard" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={4} md={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Todo />
                    </Grid>
                    <Grid item xs={12}>
                        <TodaysPlan title="My Tasks" />
                    </Grid>
                    <Grid item xs={12}>
                        <MyCalendar />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Dashboard;
