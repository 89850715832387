import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthWrapper2 from 'views/pages/authentication/AuthWrapper2';
import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import Logo from 'ui-component/Logo';
import PatientBackgroundPattern2 from '../patientonboarding/PatientBackgroundPattern2';
import PatientLoginCardRight from '../patientonboarding/PatientLoginCardRight';
import ResetPasswordCard from './ResetPasswordCard';

// ================================|| AUTH2 - LOGIN ||================================ //

const ResetPassword = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <AuthWrapper2>
            <Grid container justifyContent={matchDownSM ? 'center' : 'space-between'} alignItems="center">
                <Grid item md={6} lg={7} xs={12} sx={{ minHeight: '100vh' }}>
                    <Grid
                        sx={{ minHeight: '100vh' }}
                        container
                        alignItems={matchDownSM ? 'center' : 'flex-start'}
                        justifyContent={matchDownSM ? 'center' : 'space-between'}
                    >
                        <Grid item sx={{ display: { xs: 'none', md: 'block' }, m: 3 }}>
                            <Link to="#">
                                <Logo />
                            </Link>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            justifyContent="center"
                            alignItems="center"
                            sx={{ minHeight: { xs: 'calc(100vh - 68px)', md: 'calc(100vh - 152px)' } }}
                        >
                            <Stack justifyContent="center" alignItems="center" spacing={5} m={2}>
                                <AuthCardWrapper border={matchDownMD}>
                                    <Grid container spacing={2} justifyContent="center">
                                        <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' }, m: 3 }} align="center">
                                            <Link to="/login">
                                                <Logo />
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Stack alignItems="center" justifyContent="center" spacing={1} p={2}>
                                                <Typography
                                                    color={theme.palette.secondary.main}
                                                    gutterBottom
                                                    variant={matchDownSM ? 'h3' : 'h2'}
                                                >
                                                    Reset Password
                                                </Typography>
                                                <Typography variant="caption" fontSize="16px" textAlign="justifycenter">
                                                    To make sure your account is secure, you&apos;ll be logged out from all devices once you
                                                    set the new password.
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ResetPasswordCard loginProp={2} />
                                        </Grid>
                                    </Grid>
                                </AuthCardWrapper>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} lg={5} sx={{ position: 'relative', alignSelf: 'stretch', display: { xs: 'none', md: 'block' } }}>
                    <PatientBackgroundPattern2>
                        <PatientLoginCardRight />
                    </PatientBackgroundPattern2>
                </Grid>
            </Grid>
        </AuthWrapper2>
    );
};

export default ResetPassword;
