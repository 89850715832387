import {
    useTheme,
    Grid,
    Select,
    Typography,
    Button,
    InputLabel,
    FormControl,
    TextField,
    Autocomplete,
    Stack,
    MenuItem
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import HealthRecordsBreadCrumbs from '../HealthRecordsBreadCrumbs';
import { FormattedMessage } from 'react-intl';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AllergyTable from './AllergyTable';
import { useState } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';

const Allergies = () => {
    const theme = useTheme();
    const [show, setShow] = useState(false);
    const [currency, setCurrency] = useState('1');
    const [severity, setSeverity] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [allergyType, setAllergyType] = useState(null);
    // const [recordedDate, setRecordedDate] = useState(null);
    const [lastOccurance, setLastOccurance] = useState(null);
    const [reaction, setReaction] = useState(null);
    const handleSelectChange = (event) => {
        setCurrency(event.target.value);
    };
    const handleReactionChange = (event) => {
        setReaction(event.target.value);
    };
    const handleSeverityChange = (event) => {
        setSeverity(event.target.value);
    };
    const handleAllergyTypeChange = (event) => {
        setAllergyType(event.target.value);
    };
    const menuItems = {
        items: [
            {
                id: 'sample-docs-roadmap',
                type: 'group',
                children: [
                    {
                        id: 'allergies',
                        title: 'Allergies',
                        type: 'item',
                        url: '/healthrecords/allergies',
                        breadcrumbs: true
                    }
                ]
            }
        ]
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };
    const reactions = [
        'Anaphylaxis',
        'Asthma',
        'Anaemia',
        'Bradycardia',
        'Cardiac Arrest',
        'Chest Pain',
        'Cough',
        'Diarrhea',
        'Dizziness',
        'Edema',
        'GI Bleeding',
        'Hives/Rash',
        'Hypotension',
        'Irregular Heart Rate',
        'Itching',
        'Jaundice',
        'Joint Pain',
        'Laryngeal Edema',
        'Myopathy',
        'Nausea',
        'Nausea/Vomiting',
        'Pulmonary Toxicity',
        'Rash',
        'Respiratory Distress',
        'Respiratory Failure',
        'Shock',
        'Shortness of Breath',
        'Stomach Pain',
        'Tachycardia',
        'Wheezing',
        'Other'
    ];
    const reviewState = [
        {
            value: '1',
            label: 'Active'
        },
        {
            value: '2',
            label: 'Inactive'
        }
    ];

    const severityState = ['Mild', 'Moderate', 'Severe', 'Unknown'];
    const allergyTypeState = ['Food', 'Gadolinium Contrast Material', 'Iodine Contrast Material', 'Medication', 'Other'];

    const cardStyle = {
        pt: '15px',
        mt: '10px',
        ml: '25px',
        mr: '5px',
        pb: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12} lg={12}>
                    <HealthRecordsBreadCrumbs
                        navigation={menuItems}
                        home="/healthrecords"
                        homeTitle="Health Records"
                        separator={NavigateNextIcon}
                        sx={{
                            mb: '0px !important',
                            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Stack direction="row" spacing={2}>
                        <Typography variant="h3">
                            <FormattedMessage id="Allergies" />
                        </Typography>
                        <Button size="small" variant="contained" onClick={() => setShow((prev) => !prev)}>
                            <FormattedMessage id="Add_Allergies" />
                        </Button>
                    </Stack>
                </Grid>
                {show && (
                    <Grid item xs={12} md={12} lg={12} sx={cardStyle}>
                        <Grid container spacing={gridSpacing} sx={{ pr: 3 }}>
                            <Grid item xs={12}>
                                <Typography variant="h4"> Add Allergy </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                        label="Start Date"
                                        value={startDate}
                                        onChange={(newValue) => {
                                            setStartDate(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <TextField fullWidth id="allergy" label="Allergy" size="small" />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                {/* <TextField fullWidth id="severity" label="severity" size="small" /> */}
                                <FormControl fullWidth size="small">
                                    <InputLabel id="allergytype">Allergy Type</InputLabel>
                                    <Select
                                        id="allergytype"
                                        MenuProps={MenuProps}
                                        value={allergyType}
                                        size="small"
                                        label="Allergy Type"
                                        fullWidth
                                        onChange={handleAllergyTypeChange}
                                    >
                                        {allergyTypeState.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3} lg={3}>
                                {/* <TextField fullWidth id="severity" label="severity" size="small" /> */}
                                <FormControl fullWidth size="small">
                                    <InputLabel id="severity">Severity</InputLabel>
                                    <Select
                                        id="severity"
                                        MenuProps={MenuProps}
                                        value={severity}
                                        size="small"
                                        label="Severity"
                                        fullWidth
                                        onChange={handleSeverityChange}
                                    >
                                        {severityState.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="reaction">Reaction</InputLabel>
                                    <Select
                                        id="reaction"
                                        MenuProps={MenuProps}
                                        value={reaction}
                                        size="small"
                                        label="Reaction"
                                        fullWidth
                                        onChange={handleReactionChange}
                                    >
                                        {reactions.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <TextField
                                    id="status"
                                    select
                                    label="Status"
                                    value={currency}
                                    size="small"
                                    fullWidth
                                    onChange={handleSelectChange}
                                >
                                    {reviewState.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {/* <Grid item xs={3}>
                                <TextField fullWidth id="category" label="Category" size="small" />
                            </Grid> */}
                            {/* <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                        label="Recorded Date"
                                        value={recordedDate}
                                        onChange={(newValue) => {
                                            setRecordedDate(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid> */}

                            <Grid item xs={12} md={3} lg={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                        label="Last Occurance"
                                        value={lastOccurance}
                                        onChange={(newValue) => {
                                            setLastOccurance(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            {/*   <Grid item xs={3}>
                                <TextField fullWidth id="source" label="Source" size="small" />
                            </Grid> */}

                            <Grid item xs={12}>
                                {/* <TextareaAutosize aria-label="empty textarea" placeholder="Note" style={{ width: 400, height: 100 }} /> */}
                                <TextField size="small" fullWidth label="Note" />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <Button variant="contained" onClick={() => setShow((prev) => !prev)}>
                                        Save
                                    </Button>

                                    <Button variant="outlined" onClick={() => setShow((prev) => !prev)}>
                                        Close
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12} md={12} lg={12}>
                    <AllergyTable />
                </Grid>
            </Grid>
        </>
    );
};

export default Allergies;
