import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Badge, Button, Card, CardActions, CardContent, Chip, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMails, setRead } from 'store/slices/mail';
import MainCard from '../../../ui-component/cards/MainCard';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const MyMessages = () => {
    const theme = useTheme();
    const [data, setData] = useState([]);
    const [unreadCounts, setUnreadCounts] = useState('1');
    const mailState = useSelector((state) => state.mail);

    const [emailDetails, setEmailDetailsValue] = useState(false);
    const [selectedMail, setSelectedMail] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClick = (card) => {
        if (card.navigate !== undefined) {
            navigate(card.navigate);
        }
    };
    const handleUserChange = (data) => {
        if (data) {
            dispatch(setRead(data.id));
            dispatch(getMails());
        }
        setSelectedMail(data);
        setEmailDetailsValue((prev) => !prev);
        navigate('/messages');
    };
    const handleViewAllClick = () => {
        navigate('/messages');
    };
    useEffect(() => {
        setData(mailState.mails);
        // setUnreadCounts(mailState.unreadCount.inbox);
    }, [mailState]);

    useEffect(() => {
        // hide left drawer when email app opens
        // dispatch(openDrawer(false));
        // getData();
        dispatch(getMails());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const CustomizedBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: -15,
            top: 13,
            border: `1px solid ${theme.palette.background.paper}`,
            padding: '0 4px'
        }
    }));
    // MuiChip - label;
    const StyledChip = styled(Chip)(({ theme }) => ({
        '&.MuiChip-root': {
            width: '50%',
            height: '20%',
            marginLeft: '1%',
            fontSize: '.5rem',
            color: 'primary'
        }
        // '& .MuiChip-label': {
        //     width: '5%',
        //     height: '12%',
        //     marginLeft: '1%',
        //     fontSize: '.5rem',
        //     color: 'primary'
        // }
    }));

    return (
        <MainCard
            title={
                <CustomizedBadge badgeContent={unreadCounts} color="error">
                    <Typography variant="h3" color="secondary">
                        My Messages
                    </Typography>
                </CustomizedBadge>
            }
            content={false}
        >
            <Grid xs={12}>
                {data.map((message, index) => (
                    <Grid container>
                        <Grid item xs>
                            <Card>
                                <CardContent sx={{ p: 0, py: '16px !important', backgroundColor: theme.palette.primary.light }}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={10} lg={10} md={10} sx={{ pl: 2, pr: 2, pb: 0 }}>
                                            <Typography align="left" variant="dashboardCardContent" color="secondary">
                                                <b>{message.topic}</b> {!message.isRead && <Chip label="New" size="small" color="error" />}
                                            </Typography>
                                            <Typography align="left" variant="body1" sx={{ color: theme.palette.grey[700] }}>
                                                <b>{message.sender.name}</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} lg={2} md={2} align="right">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleUserChange(message)}
                                                sx={{ justifyContent: 'flex-end' }}
                                            >
                                                <ArrowRightIcon color="primary" fontSize="large" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <Divider />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <CardActions sx={{ p: 1, justifyContent: 'flex-end' }}>
                <Button variant="text" size="small" onClick={() => handleViewAllClick()}>
                    <b>View All</b>
                </Button>
            </CardActions>
        </MainCard>
    );
};

export default MyMessages;
