// material-ui
import { Table, TableBody, Grid, TableContainer, TableHead, TableRow, Card, useTheme } from '@mui/material';
import MuiTypography from '@mui/material/Typography';
// project imports
import SubCard from 'ui-component/cards/SubCard';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.common.white
    }
    /*  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    }, */
}));

// table data
function createData(scode, problem, startDate, problemStatus) {
    return { scode, problem, startDate, problemStatus };
}

const rows = [createData(1201005, 'Benign Essential HyperTension', '02/02/2021', 'Active')];

// ==============================|| TABLE - DENSE ||============================== //

export default function ProblemsTable() {
    const theme = useTheme();
    /*   const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#e9e8e8',
        minWidth: 600
    }; */
    return (
        <SubCard>
            <Grid item>
                <MuiTypography variant="h4" color={theme.palette.text.dark} gutterBottom>
                    Problems
                </MuiTypography>
            </Grid>
            <TableContainer sx={{ border: '1px solid', borderColor: 'Black' }}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">SNOWMED Code</StyledTableCell>
                            <StyledTableCell align="left">Problem</StyledTableCell>
                            <StyledTableCell align="left">Start Date</StyledTableCell>
                            <StyledTableCell align="left">Problem Status</StyledTableCell>
                            {/* <TableCell sx={{ pr: 3 }} align="right">
                                Protein&nbsp;(g)
                            </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow hover key={row.name}>
                                <TableCell align="left">{row.scode}</TableCell>
                                <TableCell align="left">{row.problem}</TableCell>
                                <TableCell align="left">{row.startDate}</TableCell>
                                <TableCell align="left">{row.problemStatus}</TableCell>
                                {/* <TableCell sx={{ pr: 3 }} align="right">
                                    {row.protein}
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </SubCard>
    );
}
