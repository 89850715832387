import { useTheme, Grid, Typography, Button, TextField, Stack, MenuItem, InputAdornment } from '@mui/material';
import { gridSpacing } from 'store/constant';
import HealthRecordsBreadCrumbs from '../HealthRecordsBreadCrumbs';
import { FormattedMessage } from 'react-intl';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DocumentsTable from './DocumentsTable';
import { useState } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import SubCard from 'ui-component/cards/SubCard';

// project imports
import ItemAttachments from '../ItemAttachments';

// third-party
import * as yup from 'yup';
import { useFormik } from 'formik';

const validationSchema = yup.object({
    title: yup.string().required('Task title is required'),
    dueDate: yup.date()
});

const Documents = () => {
    const theme = useTheme();
    const [show, setShow] = useState(false);
    const [date, setDate] = useState(null);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            attachments: ''
        },
        validationSchema,
        onSubmit: (values) => {
            const itemToEdit = {
                attachments: values.attachments
            };
        }
    });
    const menuItems = {
        items: [
            {
                id: 'sample-docs-roadmap',
                type: 'group',
                children: [
                    {
                        id: 'documents',
                        title: 'Documents',
                        type: 'item',
                        url: '/healthrecords/documents',
                        breadcrumbs: true
                    }
                ]
            }
        ]
    };

    const cardStyle = {
        pt: '15px',
        mt: '10px',
        ml: '25px',
        mr: '0px',
        pb: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.mainCardContentsBg,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    const searchPanelStyle = {
        pt: '15px',
        mt: '10px',
        ml: '25px',
        mr: '0px',
        pb: '15px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.white,
        border: '1px solid',
        borderRadius: 2,
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.common.grey
    };

    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={12} lg={12}>
                    <HealthRecordsBreadCrumbs
                        navigation={menuItems}
                        home="/healthrecords"
                        homeTitle="Health Records"
                        separator={NavigateNextIcon}
                        sx={{
                            mb: '0px !important',
                            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50'
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container alignItems="center" spacing={gridSpacing} sx={{ mb: 1.25 }}>
                        <Grid item>
                            <Typography variant="h3">
                                <FormattedMessage id="Documents" />
                            </Typography>
                        </Grid>
                        <Grid item flex="1">
                            <Button size="small" variant="contained" onClick={() => setShow((prev) => !prev)}>
                                <FormattedMessage id="Add_New_Document" />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {show && (
                    <Grid item xs={12} md={12} lg={12} sx={cardStyle}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Add Document </Typography>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <TextField autoFocus size="small" id="documentName" label="Document Name" type="text" fullWidth />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <TextField id="outlined-select-documentType" select fullWidth label="Document Type" size="small">
                                    <MenuItem value="reference">Reference Materials</MenuItem>
                                    <MenuItem value="tcm">TCM Education materials</MenuItem>
                                    <MenuItem value="bills">Bills and Invoices</MenuItem>
                                    <MenuItem value="insurance">Insurance Documents</MenuItem>
                                    <MenuItem value="physician">Physician Documents</MenuItem>
                                    <MenuItem value="appointment">Appointment</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12} md={3} lg={3}>
                                <TextField id="outlined-select-category" select fullWidth label="Document Category" size="small">
                                    <MenuItem value="diet">Diet</MenuItem>
                                    <MenuItem value="rest">Rest</MenuItem>
                                    <MenuItem value="exercise">Exercise</MenuItem>
                                    <MenuItem value="attitude">Attitude/Lifestyle</MenuItem>
                                    <MenuItem value="medications">Medications </MenuItem>
                                    <MenuItem value="policy">Policy Documents</MenuItem>
                                    <MenuItem value="instalment">Installment Document</MenuItem>
                                    <MenuItem value="bils_paid">Bills Paid </MenuItem>
                                    <MenuItem value="bills_due">Bills Due</MenuItem>
                                    <MenuItem value="transactions">Transactions</MenuItem>
                                    <MenuItem value="prescriptions">Prescriptions </MenuItem>
                                    <MenuItem value="topics">Topics</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <Stack direction="row" spacing={2}>
                                    <ItemAttachments attachments={formik.values.attachments} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                        label="Date"
                                        value={date}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} md={8} lg={8} sx={{ pr: 3 }}>
                                <TextField size="small" fullWidth label="Note" />
                            </Grid>

                            <Grid item xs={12} md={3} lg={3}>
                                <Stack direction="row" spacing={2}>
                                    <Button variant="contained" onClick={() => setShow((prev) => !prev)}>
                                        Save
                                    </Button>

                                    <Button variant="outlined" onClick={() => setShow((prev) => !prev)}>
                                        Close
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12} md={12} lg={12} sx={searchPanelStyle}>
                    <Grid container spacing={gridSpacing} sx={{ pr: 3 }}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h4">Search Document </Typography>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField autoFocus size="small" id="documentName" label="Document Name" type="text" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <TextField id="outlined-select-category" select fullWidth label="Document Category" size="small">
                                <MenuItem value="diet">Diet</MenuItem>
                                <MenuItem value="rest">Rest</MenuItem>
                                <MenuItem value="exercise">Exercise</MenuItem>
                                <MenuItem value="attitude">Attitude/Lifestyle</MenuItem>
                                <MenuItem value="medications">Medications </MenuItem>
                                <MenuItem value="policy">Policy Documents</MenuItem>
                                <MenuItem value="instalment">Installment Document</MenuItem>
                                <MenuItem value="bils_paid">Bills Paid </MenuItem>
                                <MenuItem value="bills_due">Bills Due</MenuItem>
                                <MenuItem value="transactions">Transactions</MenuItem>
                                <MenuItem value="prescriptions">Prescriptions </MenuItem>
                                <MenuItem value="topics">Topics</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                    label="From"
                                    value={date}
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    renderInput={(props) => <TextField size="small" fullWidth {...props} helperText="" />}
                                    label="To"
                                    value={date}
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained">Search</Button>

                                <Button variant="outlined">Clear</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <DocumentsTable />
                </Grid>
            </Grid>
        </>
    );
};

export default Documents;
