// material-ui
import { useTheme } from '@emotion/react';

import { Grid, Button, Card, CardContent, Typography, useMediaQuery } from '@mui/material';
import { gridSpacing } from 'store/constant';
// import welcomeIcon from '../welcomecard-icon.svg';
import { ReactComponent as welcomeIcon } from '../welcomecard-icon.svg';
import { ReactComponent as setUpIcon } from '../setup_card-image.svg';
import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';

// import MyCareTeam from './MyCareTeam';
import MyCareTeam from '../../dashboard/mycareteam';

import PatientWelcomeCard from '../PatientWelcomeCard';
import { useDispatch } from 'store';
import { activeItem } from 'store/slices/menu';
import TodaysPlan from './TodaysPlan';
import Todo from './Todo';
import MyCalendar from './MyCalendar';
import CareTeamTab from '../../dreams/CareTeamTab';

const PatientInitialDashboard = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('../connectprovider');
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'connectprovider');
        if (currentIndex > -1) {
            dispatch(activeItem(['connectprovider']));
        }
    };
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} lg={8} md={6}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        {/* <PatientWelcomeCard
                            primary="Welcome, Robert Hailey!"
                            content="Let us get and organise your health data, care plans to setup your DREAMS activity"
                            color="transparent linear-gradient(180deg, #0693E1 0%, #1A64F2 100%) 0% 0% no-repeat"
                            iconPrimary={welcomeIcon}
                            // height="8vw"
                            opacity="0.6"
                            iwidth={80}
                            iheight={80}
                        /> */}
                        <Grid container direction={matchDownXs ? 'column' : 'row'} spacing={1}>
                            <Grid item xs={12}>
                                <Typography color="secondary" variant="h1">
                                    Welcome, Robert Hailey!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="secondary" variant="h3">
                                    Let us get and organise your health data, care plans to setup your DREAMS activity
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <PatientWelcomeCard
                            primary={
                                <>
                                    Start setting up your DREAMS activity <br /> to accomplish goals set by your provider
                                </>
                            }
                            color={theme.palette.secondary.main}
                            content={
                                <Button disableElevation type="submit" variant="contained" color="primary">
                                    Set up my DREAMS activity
                                </Button>
                            }
                            iconPrimary={setUpIcon}
                            opacity="1.0"
                            iwidth={200}
                            iheight={125}
                            padding={1}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Card style={{ background: '#F5F7FB', border: '0.5px solid #D3D3D3' }}>
                            {/* <MainCard content={false}> */}
                            <CardContent sx={{ backgroundColor: theme.palette.cardColor.dashboardCardContentBg }}>
                                <Typography variant="dashboardCardContent" align="justify">
                                    Please download your health records from provider prior to DREAMS activity creation for a better insight
                                    to your health.
                                </Typography>
                                <Grid item xs={12} pt={2}>
                                    <Button variant="contained" color="primary" onClick={handleClick}>
                                        {' '}
                                        Download Health Records
                                    </Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <CareTeamTab callfrom="dashboard" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={4} md={6}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Todo />
                    </Grid>
                    <Grid item xs={12}>
                        <TodaysPlan />
                    </Grid>
                    <Grid item xs={12}>
                        <MyCalendar />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PatientInitialDashboard;
