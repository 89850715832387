import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Card, Drawer, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { openSideDrawer, setDrawerData } from 'store/slices/dashboard';
import MainCard from '../../../ui-component/cards/MainCard';
import TodaysPlanCard from '../todayplan/TodaysPlanCard';
import CalendarCard from './CalendarCard';
import PerfectScrollbar from 'react-perfect-scrollbar';

const MyCalendar = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const dashboard = useSelector((state) => state.dashboard);
    const { sideDrawer, drawerData } = dashboard;
    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));
    const [dateValue, setDateValue] = useState(new Date(Date.now()));
    const [isPickedDateisCurrentDate, setIsPickedDateIsCurrentDate] = useState(false);

    const handleDrawerOpen = () => {
        setOpen((prevState) => !prevState);
        dispatch(openSideDrawer(true));
    };
    const handleDrawerClose = () => {
        dispatch(setDrawerData({}));
        dispatch(openSideDrawer(false));
        setOpen(false);
        setIsPickedDateIsCurrentDate(false);
    };

    const handleChangeDate = (newValue) => {
        setDateValue(newValue);
        dispatch(openSideDrawer(true));
        setOpen((prevState) => !prevState);
        dispatch(setDrawerData(dateValue));
        // format date
        const pickedWithoutTime = new Date(newValue.getTime());
        const currentWithoutTime = new Date(new Date(Date.now()).getTime());
        pickedWithoutTime.setUTCHours(0, 0, 0, 0);
        currentWithoutTime.setUTCHours(0, 0, 0, 0);
        // check if date picked is same as current date
        if (pickedWithoutTime.getTime() === currentWithoutTime.getTime()) {
            setIsPickedDateIsCurrentDate(true);
        }
    };
    useEffect(() => {}, [matchDownSM, open]);

    // const plans = [
    //     { type: 'task', title: '1 KM Walking ', subTitile: 'With Alexander Hailey', schedule: '', completed: true, isCalendarDrawer: true },
    //     {
    //         type: 'Appointment',
    //         title: 'Schedule Appointment',
    //         subTitile: 'Schedule Appointment Dr.Miles',
    //         schedule: '',
    //         completed: true,
    //         isCalendarDrawer: true
    //     },
    //     {
    //         type: 'Food',
    //         title: 'Eat Breakfast',
    //         subTitile: 'Yougurt, Berried, Granola',
    //         schedule: '9:00AM',
    //         completed: false,
    //         isCalendarDrawer: true
    //     },
    //     {
    //         type: 'Food',
    //         title: 'Eat Lunch',
    //         subTitile: '4 oz Chicken ,Salad',
    //         schedule: '12:00PM',
    //         completed: false,
    //         isCalendarDrawer: true
    //     },
    //     { type: 'Food', title: 'Eat Dinner', subTitile: 'Oats', schedule: '7:30PM', completed: false, isCalendarDrawer: true }
    // ];
    const plans = [
        {
            type: 'Rest',
            bgColor: theme.palette.dreams.rest.primary,
            title: 'Wake Up Time',
            schedule: '6:00AM',
            completed: false,
            isCalendarDrawer: true
        },
        {
            type: 'task',
            title: 'Moderate Walking - 5000 Steps ',
            subTitile: 'With Alexander Hailey',
            schedule: '',
            bgColor: theme.palette.dreams.exercise.primary,

            completed: true,
            isCalendarDrawer: true
        },
        {
            type: 'Medication',
            title: 'Take Medicine',
            subTitile: 'Metformin 500mg',
            schedule: '8:30AM',
            bgColor: theme.palette.dreams.medication.primary,

            completed: false,
            isCalendarDrawer: true
        },
        {
            type: 'Diet',
            title: 'Breakfast',
            subTitile: 'Yogurt, Berries, Granola',
            schedule: '9:00AM',
            bgColor: theme.palette.dreams.diet.primary,

            completed: false,
            isCalendarDrawer: true
        },
        {
            type: 'Diet',
            bgColor: theme.palette.dreams.diet.primary,
            title: 'Lunch',
            subTitile: '4 oz Chicken ,Salad',
            schedule: '12:00PM',
            completed: false,
            isCalendarDrawer: true
        },
        {
            type: 'Medication',
            title: 'Take Medicine',
            subTitile: 'Neurobion Forte',
            schedule: '12:30PM',
            completed: false,
            isCalendarDrawer: true,
            bgColor: theme.palette.dreams.medication.primary
        },
        {
            type: 'Medication',
            title: 'Take Medicine',
            subTitile: 'Metformin 500mg',
            schedule: '7:00PM',
            completed: false,
            isCalendarDrawer: true,
            bgColor: theme.palette.dreams.medication.primary
        },
        {
            type: 'Diet',
            bgColor: theme.palette.dreams.diet.primary,
            title: 'Dinner',
            subTitile: 'Oats',
            schedule: '7:30PM',
            completed: false,
            isCalendarDrawer: true
        },
        {
            type: 'Rest',
            bgColor: theme.palette.dreams.rest.primary,
            title: 'Bed Time',
            schedule: '9:00PM',
            completed: false,
            isCalendarDrawer: true
        }
    ];
    return (
        <>
            {sideDrawer && (
                <Drawer
                    sx={{
                        ml: open ? 3 : 0,
                        flexShrink: 0,
                        zIndex: 1200,
                        overflowX: 'hidden',
                        width: { xs: 320, md: 450 },
                        '& .MuiDrawer-paper': {
                            height: '100vh',
                            width: { xs: '100%', md: 450 },
                            position: 'fixed',
                            border: 'none',
                            borderRadius: '0px'
                        }
                    }}
                    variant="temporary"
                    anchor="right"
                    open={open}
                    ModalProps={{ keepMounted: true }}
                    onClose={handleDrawerOpen}
                >
                    <Box sx={{ p: 3 }}>
                        <Grid container alignItems="center" spacing={0.5} justifyContent="space-between">
                            <Grid item sx={{ width: 'calc(100% - 50px)' }}>
                                <Stack direction="row" spacing={0.5} alignItems="center">
                                    <Typography
                                        variant="h4"
                                        color="secondary"
                                        sx={{
                                            display: 'inline-block',
                                            width: 'calc(100% - 34px)',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            verticalAlign: 'middle'
                                        }}
                                    >
                                        My Calendar tasks on {dateValue && dateValue.toUTCString().split(' ').slice(0, 4).join(' ')}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <IconButton sx={{ p: 0.5, minWidth: 32 }} onClick={() => handleDrawerClose()}>
                                    <CloseIcon />
                                </IconButton>
                                {/* <Button variant="text" onClick={() => setOpen(true)}>
                            <CloseIcon />
                        </Button> */}
                            </Grid>
                        </Grid>
                    </Box>
                    <PerfectScrollbar component="div" style={{ background: theme.palette.primary.light }}>
                        <Card sx={{ m: 2 }}>
                            <TodaysPlanCard
                                title="Todays Plan"
                                plans={plans}
                                handleDrawerOpen={handleDrawerOpen}
                                isPickedDateisCurrentDate={isPickedDateisCurrentDate}
                                callfrom="Calendar"
                            />
                        </Card>
                    </PerfectScrollbar>
                </Drawer>
            )}
            <MainCard
                title={
                    <>
                        <Typography variant="h3" color="secondary">
                            My Calendar
                        </Typography>
                    </>
                }
                content={false}
            >
                <CalendarCard handleChangeDate={handleChangeDate} dateValue={dateValue} />
            </MainCard>
        </>
    );
};

export default MyCalendar;
