import { Grid, useTheme } from '@mui/material';
import CalendarCard from 'views/dashboard/mycalendar/CalendarCard';
import MainCard from 'ui-component/cards/MainCard';
import { useState } from 'react';

const MyCalendar = () => {
    const theme = useTheme();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#e9e8e8'
    };
    const [dateValue, setDateValue] = useState(new Date(Date.now()));

    const handleChangeDate = (newValue) => {
        setDateValue(newValue);
    };
    return (
        <MainCard title="My Calendar" content={false}>
            <Grid item>
                <CalendarCard handleChangeDate={handleChangeDate} dateValue={dateValue} />
            </Grid>
        </MainCard>
    );
};

export default MyCalendar;
