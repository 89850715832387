import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
    CardActions
} from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';

import { useDispatch } from 'store';
import { getUsersListStyle1 } from 'store/slices/user';

// assets
import EmailIcon from '@mui/icons-material/Email';
import VideocamIcon from '@mui/icons-material/Videocam';
import CallIcon from '@mui/icons-material/Call';
// const avatarImage = require.context('assets/images/profile', true);

import GloriaAvatar from '../../assets/images/careteam/gloria_hailey_proxy_wife.png';
import AlexanderAvatar from '../../assets/images/careteam/alexander_hailey_son.png';

// ==============================|| USER LIST 1 ||============================== //

const ProxyUserList = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.cardColor.dashboardCardContentBg,
        // border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#e9e8e8'
    };

    // const [data, setData] = React.useState([]);
    // const { usersS1 } = useSelector((state) => state.user);

    // React.useEffect(() => {
    //     setData(usersS1);
    // }, [usersS1]);

    React.useEffect(() => {
        dispatch(getUsersListStyle1());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow sx={cardStyle}>
                        <TableCell>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Avatar alt="Gloria Hailey" src={GloriaAvatar} x={{ borderRadius: 0, width: 70, height: 70, ml: 3 }} />
                                </Grid>
                                <Grid item xs zeroMinWidth>
                                    <Typography align="left" variant="subtitle1" component="div">
                                        Gloria Hailey
                                    </Typography>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid item xs zeroMinWidth>
                                <Typography align="left" variant="subtitle1" component="div">
                                    Proxy
                                </Typography>
                                <Typography align="left" variant="body1" noWrap>
                                    Wife
                                </Typography>
                            </Grid>
                        </TableCell>

                        <TableCell align="center" sx={{ pr: 3 }}>
                            <Stack direction="row" justifyContent="center" alignItems="center">
                                <Tooltip placement="top" title="Email">
                                    <IconButton color="primary" aria-label="email" size="large">
                                        <EmailIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Call">
                                    <IconButton size="large" color="primary">
                                        <CallIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Call">
                                    <IconButton size="large" color="primary">
                                        <VideocamIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }}>
                            <Button variant="text" size="small">
                                View Details
                            </Button>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={cardStyle}>
                        <TableCell>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Avatar
                                        alt="Alexander Hailey"
                                        src={AlexanderAvatar}
                                        x={{ borderRadius: 0, width: 70, height: 70, ml: 3 }}
                                    />
                                </Grid>
                                <Grid item xs zeroMinWidth>
                                    <Typography align="left" variant="subtitle1" component="div">
                                        Alexander Hailey
                                    </Typography>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Grid item xs zeroMinWidth>
                                <Typography align="left" variant="subtitle1" component="div">
                                    Family Care Team
                                </Typography>
                                <Typography align="left" variant="body1" noWrap>
                                    Son
                                </Typography>
                            </Grid>
                        </TableCell>

                        <TableCell align="center" sx={{ pr: 3 }}>
                            <Stack direction="row" justifyContent="center" alignItems="center">
                                <Tooltip placement="top" title="Email">
                                    <IconButton color="primary" aria-label="email" size="large">
                                        <EmailIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Call">
                                    <IconButton size="large" color="primary">
                                        <CallIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Call">
                                    <IconButton size="large" color="primary">
                                        <VideocamIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }}>
                            <Button variant="text" size="small">
                                View Details
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ProxyUserList;
